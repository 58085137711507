import DefaultModal from './DefaultModal'
import SmallModal from './SmallModal'
import PersistantModal from './PersistantModal'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../examplesImporter'

const exampleComponents = {
  DefaultModal,
  SmallModal,
  PersistantModal
}

export { examplesArray, exampleComponents }
