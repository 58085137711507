import ButtonTooltip from './ButtonTooltip'
import IconTooltip from './IconTooltip'
import WrapTextTooltip from './WrapTextTooltip'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../examplesImporter'

const exampleComponents = {
  ButtonTooltip,
  IconTooltip,
  WrapTextTooltip
}

export { examplesArray, exampleComponents }
