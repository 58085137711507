<script>
import { mapMutations } from 'vuex'
import { SnackbarItem, SnackbarType } from 'clevertap-dashboard-components/components/Snackbar'
export default {
  name: 'SnackbarSuccess',
  methods: {
    ...mapMutations('site', { queueSnackbar: 'QUEUE_SNACKBAR' }),
    showSnackbar () {
      this.queueSnackbar(new SnackbarItem({
        title: 'Campaign succesfully saved',
        duration: 3000,
        type: SnackbarType.SUCCESS
      }))
    }
  }
}
</script>
