<template>
  <ct-unified-select
    variant="autocomplete"
    label="Label"
    hint="Hint"
    persistent-hint
    :items="['Foo', 'Bar', 'Default Value', 'Buzz']"
  ></ct-unified-select>
</template>

<script>
import CtUnifiedSelect from 'clevertap-dashboard-components/components/Select/CtUnifiedSelect'

export default {
  components: {
    CtUnifiedSelect
  }
}
</script>
