<template>
  <div>
    <div>
      <h2>Interaction color palette</h2>
      <p>
        These colors denote component interactions. They are widely used throughout the CleverTap form field and icons interaction.
      </p>
    </div>
    <div class="d-flex justify-space-around">
      <color-tile :rgb="'231, 68, 68'" :hex="'#E74444'" :title="'Cinnabar'" :dark="true"></color-tile>
      <color-tile :rgb="'232, 82, 141'" :hex="'#E8528D'" :title="'Pink'" :dark="true"></color-tile>
      <color-tile :rgb="'170, 73, 247'" :hex="'#AA49F7'" :title="'Orchid'" :dark="true"></color-tile>
    </div>
    <div class="d-flex justify-space-around mt-5">
      <color-tile :rgb="'112, 64, 229'" :hex="'#7040E5'" :title="'Skate Blue'" :dark="true"></color-tile>
      <color-tile :rgb="'81, 108, 245'" :hex="'#516CF5'" :title="'Information Purple 1'" :dark="true"></color-tile>
      <color-tile :rgb="'78, 168, 240'" :hex="'#4EA8F0'" :title="'Summer Sky'" :dark="true"></color-tile>
    </div>
    <div class="d-flex justify-space-around mt-5">
      <color-tile :rgb="'97, 187, 168'" :hex="'#61BBA8'" :title="'Puerto Rico'" :dark="true"></color-tile>
      <color-tile :rgb="'127, 208, 79'" :hex="'#7FD04F'" :title="'Mantis'"></color-tile>
      <color-tile :rgb="'197, 234, 100'" :hex="'#C5EA64'" :title="'Lemon'"></color-tile>
    </div>
    <div class="d-flex justify-space-around mt-5">
      <color-tile :rgb="'249, 220, 100'" :hex="'#F9DC64'" :title="'Dandelion'"></color-tile>
      <color-tile :rgb="'249, 186, 89'" :hex="'#F4BA59'" :title="'Casablanca'"></color-tile>
      <color-tile :rgb="'241, 137, 78'" :hex="'#F1894E'" :title="'Crusta'" :dark="true"></color-tile>
    </div>
    <p>
    </p>
  </div>
</template>

<script>
import ColorTile from '../ColorTile.vue'
export default {
  components: {
    ColorTile
  }
}
</script>

<style scoped lang="scss">
  div {
    h2 {
      margin-top: 2.2rem;
      margin-left: 1.6rem;
    }
    p {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
      margin-left: 1.6rem;
    }
  }
</style>
