<template>
  <div>
    <div class="mb-6 pa-2 pb-3 code-layout">
      <div class="d-flex justify-space-between ml-4 mt-3">
        <h3 class="t-14 font-weight-semibold">{{ _heading }}</h3>
        <div>
          <ct-button-toggle v-model="toggleView" borderless mandatory dense>
            <ct-button color="toggleBtn">
              <ct-icon>mdi-format-align-justify</ct-icon>
              <!--need ct-icon for this-->
            </ct-button>
            <ct-button color="toggleBtn">
              <ct-icon>cticon-integration</ct-icon>
            </ct-button>
          </ct-button-toggle>
        </div>
      </div>
      <div class="mt-2 mx-2 display-component">
        <div class="pa-3" v-if="toggleView === 0"><slot></slot></div>
        <copy-code-section :code="displayCode" v-else></copy-code-section>
      </div>
    </div>
  </div>
</template>

<script>
import CtButton from 'clevertap-dashboard-components/components/Button/CtButton'
import CtButtonToggle from 'clevertap-dashboard-components/components/Button/CtButtonToggle'
import CtIcon from 'clevertap-dashboard-components/components/Icon'
import CopyCodeSection from './CopyCodeSection.vue'
export default {
  name: 'CodeLayout',
  components: {
    CtButton,
    CtButtonToggle,
    CtIcon,
    CopyCodeSection
  },
  props: {
    heading: String,
    displayCode: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      toggleView: 0
    }
  },
  computed: {
    _heading () {
      return this.heading.replace(/([A-Z])/g, ' $1')
    }
  }
}
</script>
<style scoped>
.code-layout {
  border-radius: 4px;
  background-color: #f9f9fb;
}
.display-component {
  border-radius: 4px;
  background-color: #ffffff;
}
</style>
