const components = {
  state: () => ({
    componentName: '',
    componentTag: '',
    sourceURL: 'https://bitbucket.clevertap.net/projects/DAS/repos/dashboard-components/browse'
  }),

  mutations: {
    SET_PROPERTY (state, payload) {
      let keys = Object.keys(payload)
      if (keys.length > 0) {
        keys.forEach(key => {
          if (key in state) {
            state[key] = payload[key]
          }
        })
      }
    }
  },
  actions: {},
  namespaced: true
}

export default components
