<template>
    <div class="d-flex justify-space-around">
      <ct-row>
        <p>Breadcrumbs with Icon as Divider
          <ct-breadcrumbs :items=items>
            <template v-slot:divider>
              <v-icon>mdi-forward</v-icon>
            </template>
          </ct-breadcrumbs>
        </p>
      </ct-row>
      <ct-row>
        <p>Breadcrumbs with different Divider
          <ct-breadcrumbs :items=items divider=">"></ct-breadcrumbs>
        </p>
      </ct-row>
    </div>
</template>

<script>
import CtBreadcrumbs from 'clevertap-dashboard-components/components/Breadcrumbs/CtBreadcrumbs.vue'
import CtRow from 'clevertap-dashboard-components/components/Grid/GridSystem/CtRow.vue'
export default {
  components: {
    CtBreadcrumbs,
    CtRow
  },
  data: () => ({
    items: [
      {
        text: 'Link 1',
        disabled: false,
        href: '#'
      },
      {
        text: 'Link 2',
        disabled: false,
        href: '#'
      },
      {
        text: 'Link 3',
        disabled: false,
        href: '#'
      }
    ]
  })
}

</script>
