<template>
  <div class="pb-4 mr-6">
    <div v-for="(detail, index) in propertyList" :key="detail.name" class="pt-6">
      <ct-row>
        <ct-col cols="3">
          <h6 class="t-12 font-weight-medium">Name</h6>
          <h6 class="t-14 font-weight-bold name">{{detail.name}}</h6>
        </ct-col>
        <ct-col cols="3">
          <h6 class="t-12 font-weight-medium">Type</h6>
          <h6 class="t-14 font-weight-bold">{{detail.type}}</h6>
        </ct-col>
        <ct-col cols="3">
          <h6 class="t-12 font-weight-medium">Default</h6>
          <h6 class="t-14 font-weight-bold">{{detail.default}}</h6>
        </ct-col>
      </ct-row>
      <div class="flex xs12 mt-2 mb-4" v-if="detail.acceptedValues">
        <h6 class="t-12 font-weight-medium mt-4">Accepted Values</h6>
        <h6 class="t-14 font-weight-regular mt-1">{{detail.acceptedValues.join(', ')}}</h6>
      </div>
      <div class="flex xs12 mt-2" v-if="detail.description">
        <h6 class="t-12 font-weight-medium mt-4">Description</h6>
        <vue-markdown class="t-14 font-weight-regular mt-1">{{detail.description}}</vue-markdown>
      </div>
      <div class="divider" v-if="index != propertyList.length - 1"></div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import { CtRow, CtCol } from 'clevertap-dashboard-components/components/Grid/GridSystem'
export default {
  name: 'PropsDescription',
  components: {
    VueMarkdown,
    CtRow,
    CtCol
  },
  props: {
    propertyList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.name {
  color: #7040e5;
}
.divider {
  box-sizing: border-box;
  height: 1px;
  border: 1px solid #f1f2f5;
}
>>> code {
  color: #7040e5;
  font-family: "Proxima Nova";
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  box-shadow: none !important;
  border-radius: 2px;
  background-color: #e3dafa;
}
</style>
