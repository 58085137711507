<template>
  <div class="d-flex justify-space-around">
    Generic alert dialog
  </div>
</template>

<script>
export default {
  name: 'DefaultAlertGeneric.vue',
  components: {
  }
}
</script>

<style scoped>

</style>
