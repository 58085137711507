<template>
  <div class="d-flex justify-space-around">
    <ct-textbox placeholder="Search" is-search disabled></ct-textbox>
    <ct-autocomplete placeholder="Search" is-search :items="itemsObject" :item-text="'customText'" :item-value="'customValue'" :hint="selectboxHint" :label="selectboxLabel" disabled></ct-autocomplete>
  </div>
</template>

<script>
import CtTextbox from 'clevertap-dashboard-components/components/Textfield/CtTextfield'
import CtAutocomplete from 'clevertap-dashboard-components/components/Autocomplete/CtAutocomplete'
export default {
  components: {
    CtTextbox,
    CtAutocomplete
  },
  data: () => ({
    itemsObject: [
      { id: 0, customText: 'Foo', customValue: 'foo' },
      { id: 1, customText: 'Bar', customValue: 'bar' },
      { id: 2, customText: 'Event', customValue: 'event' },
      { id: 3, customText: 'Buzz', customValue: 'buzz' }
    ]
  })
}
</script>
