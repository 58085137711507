<template>
  <div>
    <ct-row>
      <ct-col>
        <ct-table
          :inline-style="{ height: 'auto', maxHeight: '400px' }"
          scrollable="scrollable"
          :columns.sync="cols"
          :data-items="tableItems"
          :sortable="defaultTable.sortable"
          :sort="defaultTable.sort"
          :selectAll="defaultTable.selectAll"
          :loading="defaultTable.loading"
          :navigation-bar="defaultTable.navBar"
          :search-bar="defaultTable.searchBar"
          :tool-bar="defaultTable.toolBar"
          :selected-field="'selected'"
          @selection="handleRowSelection"
          @selection-all="handleSelectAll"
          @searchText="handleSearch"
          @addAction="handleAddAction"
          @pagechange="handlePageChange"
          @sortchange="handleSort"
          :col-selector="defaultTable.colSelector"
          :is-percentage-width="true"
        >
        <template v-slot:searchbar>
          <ct-textfield :placeholder="placeholder" v-model="searchInput" hide-details class="search-input" @input="handleSearch">
            <ct-icon class="mt-1" slot="prepend-inner" size="16">
              cticon-search
            </ct-icon>
          </ct-textfield>
          <ct-filter filter-heading="Set Filters" class="filters">
            <template #activator="{on}">
              <ct-button color="secondaryBtn" icon v-on="on">
                <v-badge
                color="primary"
                :content="filterCount"
                transition="slide-x-transition"
                >
                  <template v-slot:badge>{{filtercount}}</template>
                  <ct-icon>cticon-filter</ct-icon>
                </v-badge>
              </ct-button>
            </template>
            <div>
              <ct-combobox
                v-model="chips"
                :items="items"
                chips
                clearable
                label="Label"
                multiple
                solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong>&nbsp;
                  </v-chip>
                </template>
              </ct-combobox>
              <ct-unified-select
                v-model="input1"
                placeholder="Some Placeholder Text"
                label="Label"
                :items="[1,2,3]"
              ></ct-unified-select>
              <ct-unified-select
                v-model="input2"
                placeholder="Some Placeholder Text"
                label="Label"
                :items="['','Sent','Reply','Error']"
              ></ct-unified-select>
            </div>
          </ct-filter>
          <ct-button @click="handleAddAction" color="secondaryBtn" class="Addbtn"><span v-html="actionButtonHTML"></span></ct-button>
        </template>
        <template v-slot:toolbar>
            Showing {{rows}} rows
        </template>
        </ct-table>
      </ct-col>
    </ct-row>
  </div>
</template>
<script>
// Components
import CtTable from '@/../../components/Table/CtTableColSelectorTemp'
import CtCol from '@/../../components/Grid/GridSystem/CtCol'
import CtRow from '@/../../components/Grid/GridSystem/CtRow'
import CtTextfield from '../../../../../components/Textfield/CtTextfield.vue'
import CtFilter from '../../../../../components/Filter/CtFilter.vue'
import CtIcon from '../../../../../components/Icon/CtIcon.vue'
import CtButton from '../../../../../components/Button/CtButton.vue'
import CtCombobox from '../../../../../components/Combobox/CtCombobox.vue'
import CtUnifiedSelect from 'clevertap-dashboard-components/components/Select/CtUnifiedSelect'

export default {
  components: {
    CtRow,
    CtCol,
    CtTable,
    CtTextfield,
    CtFilter,
    CtIcon,
    CtButton,
    CtCombobox,
    CtUnifiedSelect
  },

  data: () => ({

    cols: [
      {
        field: 'header1',
        title: `Header 1`,
        helpText: 'This is some help text',
        width: '20%',
        locked: true
      },
      {
        field: 'header2',
        title: 'Header 2',
        helpText: 'Look another help text!',
        width: '20%',
        locked: true
      },
      {
        field: 'numbers',
        title: 'Numbers',
        width: '10%'
      },
      {
        field: 'header4',
        title: 'Header 4',
        width: '10%'
      },
      {
        field: 'header5',
        title: 'Header 5',
        width: '20%',
        hidden: true
      },
      {
        field: 'header6',
        title: 'Header 6',
        width: '20%',
        hidden: true
      },
      {
        field: 'header7',
        title: 'Header 7',
        width: '20%',
        hidden: true
      },
      {
        field: 'header8',
        title: 'Header 8',
        width: '20%',
        hidden: true
      }
    ],

    allowUnsort: true,
    multiple: false,
    page: 1,
    skip: 0,
    take: 10,
    tableItems: [],
    defaultTable: {
      loading: false,
      selectAll: false,
      sortable: false,
      sort: [{ dir: 'asc', field: 'header2' }],
      searchBar: true,
      navBar: false,
      toolBar: true,
      colSelector: true
    },
    rows: 50,
    chips: [],
    items: ['Mobile Push', 'SMS', 'Email'],
    input1: 0,
    input2: ''
  }),
  props: {
    actionButtonHTML: {
      type: String,
      default: 'Add'
    },
    placeholder: {
      type: String,
      default: 'Search'
    }
  },

  computed: {
    result: {
      get: function () {
        return this.tableItems.slice(this.page - 1, this.page * this.take)
      }
    },
    filtercount: {
      set: function () {
        return 0
      },
      get: function () {
        return (this.chips.length === 0 ? 0 : 1) + (this.input1 === 0 ? 0 : 1) + (this.input2 === '' ? 0 : 1)
      }
    }
  },

  methods: {
    handleRowSelection (e) {
      e.item.selected = e.selection
    },

    handleSelectAll (e) {
      this.tableItems.forEach((item, index) => { item.selected = e.selection })
    },

    handleSort (e) {
      let sortProperties = this.defaultTable.sort.length
      let currentColSort = sortProperties ? this.defaultTable.sort.find(item => item.field === e.field) : null
      if (currentColSort) {
        currentColSort.dir = (currentColSort.dir === 'asc') ? 'desc' : 'asc'
      } else {
        this.defaultTable.sort = [{ field: e.field, dir: 'asc' }]
      }
    },

    pageChangeHandler (e) {
      this.tableItems.forEach((item, index) => { item.selected = false })
      this.defaultTable.loading = true
      // Fetch new data here
      setTimeout(() => {
        this.defaultTable.loading = false
      }, 500)
    },

    getNestedValue (fieldName, dataItem) {
      const path = fieldName.split('.')
      let data = dataItem
      path.forEach((p) => {
        data = data ? data[p] : undefined
      })

      return data
    },
    remove (item) {
      this.chips.splice(this.chips.indexOf(item), 1)
      this.chips = [...this.chips]
    }
  },

  mounted () {
  /* eslint-disable no-multi-spaces, no-trailing-spaces */
    [...Array(100)].forEach((j, i) => (
      this.tableItems.push({
        id: i,
        selected: false,
        header1: `Row ${i + 1}`,
        header2: (i % 2) ? 'Column 2' : 'Some reallyyyyyyy big column text',
        numbers: Math.floor((Math.random() * 1000)),
        header4: 'Column 4',
        header5: 'Column 5',
        header6: 'Column 6',
        header7: 'Column 7',
        header8: 'Column 8'
      })
    ))
  /* eslint-enable no-multi-spaces, no-trailing-spaces */
  }
}
</script>
<style lang='scss' scoped>
.title {
  height: 72px
}
.filters {
  margin-left: 5px;
}
.Addbtn {
  margin-inline-start: 700px;
}
</style>
