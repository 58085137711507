import PrimaryPalette from './PrimaryPalette'
import NeutralPalette from './NeutralPalette'
import NotesPalette from './NotesPalette'
import ChartsPalette from './ChartsPalette'
import InteractionPalette from './InteractionPalette'
import ButtonsConfirmationsAlertsNagsPalette from './ButtonsConfirmationsAlertsNagsPalette'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../examplesImporter'

const exampleComponents = {
  PrimaryPalette,
  NeutralPalette,
  NotesPalette,
  ChartsPalette,
  InteractionPalette,
  ButtonsConfirmationsAlertsNagsPalette
}

export { examplesArray, exampleComponents }
