import client from '../../api/client'
import { ACCOUNT_ID } from '../../constants'
import querystring from 'querystring'

export default {
  getAccounts () {
    if (!ACCOUNT_ID) {
      return
    }
    const url = `/${ACCOUNT_ID}/json/user/accounts`
    return new Promise((resolve, reject) => {
      client.post(url).then(response => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          reject(response)
        }
      }).catch((error = {}) => {
        resolve({ error: error.message || 'unknown' })
      })
    })
  },
  createTestAccount (name, parentId) {
    if (!ACCOUNT_ID) {
      return
    }
    const data = {
      prodAccountId: parentId,
      accountName: name
    }
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    const url = `/${ACCOUNT_ID}/json/create-account/createTestAccount`
    return new Promise((resolve, reject) => {
      client.post(url, querystring.encode(data), config).then(response => {
        if (response.status === 200) {
          let data = {}
          if (response.data.success && response.data.test_accounts.length > 0) {
            let newAccount = response.data.test_accounts[0]
            data = { result: 'success', accountName: newAccount.test_account_name, accountId: newAccount.test_account_id }
          } else {
            data = { result: 'error', error: response.data.error }
          }
          resolve(data)
        } else {
          reject(response)
        }
      }).catch((error = {}) => {
        resolve({ error: error.message || 'unknown' })
      })
    })
  },
  getPlanFeatureInfo () {
    if (!ACCOUNT_ID) {
      return
    }
    const url = `/${ACCOUNT_ID}/json/findlimitsforfeature.html`
    return new Promise((resolve, reject) => {
      client.get(url).then(response => {
        if (response.status === 200) {
          resolve(response.data ? response.data : null)
        } else {
          resolve(null)
        }
      }).catch((error = {}) => {
        resolve(null)
      })
    })
  },
  getPlanAndEventCount () {
    if (!ACCOUNT_ID) {
      return
    }
    const url = `/${ACCOUNT_ID}/json/billing-manager.html?action=getCurrentPlanAndEventCountInfo`
    return new Promise((resolve, reject) => {
      client.get(url).then(response => {
        if (response.status === 200) {
          resolve(response.data ? response.data : null)
        } else {
          resolve(null)
        }
      }).catch((error = {}) => {
        resolve(null)
      })
    })
  },
  getRBACDetails () {
    if (!ACCOUNT_ID) {
      return
    }
    const url = `/${ACCOUNT_ID}/json/getRBACDetails.html`
    return new Promise((resolve, reject) => {
      client.get(url).then(response => {
        if (response.status === 200) {
          resolve(response.data ? response.data : null)
        } else {
          resolve(null)
        }
      }).catch((error = {}) => {
        resolve(null)
      })
    })
  }
}
