import { render, staticRenderFns } from "./DefaultChip.vue?vue&type=template&id=e14490c8&"
import script from "./DefaultChip.vue?vue&type=script&lang=js&"
export * from "./DefaultChip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports