<template>
  <div>
    <top-tabs :hideTabs="['api', 'examples']"/>
    <router-view />
  </div>
</template>

<script>
import TopTabs from '../TopTabs'
import { mapMutations } from 'vuex'

const PageProperties = {
  componentName: 'Grid System',
  componentTag: ''
}

export default {
  name: 'GridSystem',
  components: {
    TopTabs
  },
  methods: {
    ...mapMutations('components', { setProperty: 'SET_PROPERTY' })
  },
  mounted () {
    this.setProperty(PageProperties)
  }
}
</script>

<style lang="scss" scoped>
// div {
//     padding-left: 4px;
// }
</style>
