<template>
  <div class="page">
    <TopBar />
    <CtSheet class="elevation-1">
      <router-view />
    </CtSheet>
  </div>
</template>
<script>
import TopBar from './TopBar'
import CtSheet from 'clevertap-dashboard-components/components/Sheet/CtSheet'

export default {
  components: {
    TopBar,
    CtSheet
  }
}
</script>
<style lang="sass" scoped>

</style>
