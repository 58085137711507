<template>
  <div class="d-flex justify-space-around">
    <div class="text-center">
      <ct-tabs>
        <ct-tab>Item 1</ct-tab>
        <ct-tab>Item 2</ct-tab>
        <ct-tab>Item 3</ct-tab>
        <ct-tab>Item 4</ct-tab>
      </ct-tabs>
    </div>
  </div>
</template>

<script>
import CtTabs from 'clevertap-dashboard-components/components/Tabs/CtTabs'
import CtTab from 'clevertap-dashboard-components/components/Tabs/CtTab'
export default {
  components: {
    CtTabs,
    CtTab
  }
}
</script>
