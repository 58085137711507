<template>
  <ct-tooltip bottom>
    <template #activator="{ on }">
        <ct-icon v-on="on">cticon-help</ct-icon>
    </template>
    <span>This is help icon tooltip</span>
  </ct-tooltip>
</template>

<script>
import CtTooltip from 'clevertap-dashboard-components/components/Tooltip/CtTooltip'
import CtIcon from 'clevertap-dashboard-components/components/Icon/CtIcon'
export default {
  name: 'IconTooltip',
  components: {
    CtTooltip,
    CtIcon
  }
}
</script>

<style scoped>

</style>
