<template>
  <ct-unified-select
    variant="menu"
    :items="items"
  ></ct-unified-select>
</template>

<script>
import CtUnifiedSelect from 'clevertap-dashboard-components/components/Select/CtUnifiedSelect'

export default {
  components: {
    CtUnifiedSelect
  },
  data: () => ({
    items: [
      {
        text: 'Item 1',
        value: '2'
      }, {
        text: 'Default Value',
        value: '3'
      }, {
        text: 'Item 4',
        value: '4'
      }, {
        text: 'Item 2',
        value: '5'
      }
    ]
  })
}
</script>
