const navItems = [
  {
    subheader: 'Components',
    subheaderShort: 'C',
    children: [
      {
        icon: 'cticon-globe',
        tag: 'dropdowns',
        title: 'Dropdowns',
        children: [
          {
            to: '/dropdowns/single-select',
            title: 'Single Select'
          },
          {
            to: '/dropdowns/multi-select',
            title: 'Multi Select'
          }
        ]
      },
      {
        icon: 'cticon-globe',
        tag: 'selection-controls',
        title: 'Selection Controls',
        children: [
          {
            to: '/selection-controls/checkbox',
            title: 'Checkbox'
          },
          {
            to: '/selection-controls/radio',
            title: 'Radio'
          },
          {
            to: '/selection-controls/switch',
            title: 'Switch'
          }
        ]
      },
      {
        icon: 'cticon-globe',
        tag: 'nagbar',
        title: 'Nags',
        to: '/nags'
      },
      {
        icon: 'cticon-globe',
        tag: 'snackbar',
        title: 'Snackbar',
        to: '/snackbar'
      },
      {
        icon: 'cticon-globe',
        tag: 'button',
        title: 'Button',
        to: '/buttons/SimpleButton'
      },
      {
        icon: 'cticon-globe',
        tag: 'button-group',
        title: 'Button Group',
        to: '/buttons/ButtonGroup'
      },
      {
        icon: 'cticon-globe',
        tag: 'search',
        title: 'Search',
        to: '/Search/Search'
      },
      {
        icon: 'cticon-globe',
        tag: 'typography',
        title: 'Typography',
        to: '/typography'
      },
      {
        icon: 'cticon-globe',
        tag: 'alert',
        title: 'Alert',
        to: '/alerts/AlertGeneric'
      },
      {
        icon: 'cticon-globe',
        tag: 'tooltip',
        title: 'Tooltip',
        to: '/tooltip'
      },
      {
        icon: 'cticon-globe',
        tag: 'colors',
        title: 'Colors',
        to: '/colors'
      },
      {
        icon: 'cticon-globe',
        tag: 'textField',
        title: 'TextField',
        to: '/textField'
      },
      {
        icon: 'cticon-globe',
        tag: 'textArea',
        title: 'TextArea',
        to: '/textArea'
      },
      {
        icon: 'cticon-globe',
        tag: 'BreadCrumbs',
        title: 'Breadcrumbs',
        to: '/breadcrumbs'
      },
      {
        icon: 'cticon-globe',
        tag: 'Modal',
        title: 'Modal',
        to: '/modal'
      },
      {
        icon: 'cticon-globe',
        tag: 'Chips',
        title: 'Chips',
        to: '/chips'
      },
      {
        icon: 'cticon-globe',
        tag: 'Grid System',
        title: 'Grid System',
        to: '/grid-system'
      },
      {
        icon: 'cticon-globe',
        tag: 'Card',
        title: 'Card',
        to: '/card'
      },
      {
        icon: 'cticon-globe',
        tag: 'Tabs',
        title: 'Tabs',
        to: '/tabs'
      },
      {
        icon: 'cticon-globe',
        tag: 'Tag',
        title: 'Tags',
        to: '/tags'
      },
      {
        icon: 'cticon-globe',
        tag: 'TimePicker',
        title: 'Time Picker',
        to: '/timePicker'
      },
      {
        icon: 'cticon-globe',
        tag: 'Table',
        title: 'Table',
        to: '/tables'
      }
    ]
  }
]

export default navItems
