import DefaultSearch from './DefaultSearch'
import SearchWithAutocomplete from './SearchWithAutocomplete'
import DisabledSearch from './DisabledSearch'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../examplesImporter'

const exampleComponents = {
  DefaultSearch,
  SearchWithAutocomplete,
  DisabledSearch
}

export { examplesArray, exampleComponents }
