import defaultTextArea from './defaultTextArea'
import defaultTextAreaHint from './defaultTextAreaHint'
import textAreaLabel from './textAreaLabel'
import textAreaLabelHint from './textAreaLabelHint'
import disabledtextAreaLabelHint from './disabledtextAreaLabelHint'
import errorTextArea from './errorTextArea'
import textAreaCount from './textAreaCount'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../examplesImporter'

const exampleComponents = {
  defaultTextArea,
  defaultTextAreaHint,
  textAreaLabel,
  textAreaLabelHint,
  disabledtextAreaLabelHint,
  errorTextArea,
  textAreaCount
}

export { examplesArray, exampleComponents }
