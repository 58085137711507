<template>
    <div class="d-flex justify-space-around">
        <ct-breadcrumbs :items=items>
        </ct-breadcrumbs>
    </div>
</template>

<script>
import CtBreadcrumbs from 'clevertap-dashboard-components/components/Breadcrumbs/CtBreadcrumbs.vue'
export default {
  components: {
    CtBreadcrumbs
  },
  data: () => ({
    items: [
      {
        text: 'Link 1',
        disabled: false,
        href: '#'
      },
      {
        text: 'Link 2',
        disabled: false,
        href: '#'
      },
      {
        text: 'Link 3',
        disabled: false,
        href: '#'
      }
    ]
  })
}

</script>
