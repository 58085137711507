<template>
  <div>
    <top-tabs />
    <router-view />
  </div>
</template>

<script>
import TopTabs from '../../TopTabs'
import { mapMutations } from 'vuex'

const PageProperties = {
  componentName: 'VBtn',
  componentTag: 'ct-button'
}

export default {
  name: 'SimpleButton',
  components: {
    TopTabs
  },
  methods: {
    ...mapMutations('components', { setProperty: 'SET_PROPERTY' })
  },
  mounted () {
    this.setProperty(PageProperties)
  }
}
</script>
