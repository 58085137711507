<template>
  <div class="api pa-6 t-16">
    <div class="title">API</div>
    <ct-tabs vertical slider-size="4" background-color="#f9f9fb">
      <ct-tab class="vertical-tabs">Props</ct-tab>
      <ct-tab class="vertical-tabs">Events</ct-tab>
      <ct-tab class="vertical-tabs">Slots</ct-tab>
      <v-tab-item class="v-tab-items">
        <div>
          <PropsDescription :propertyList="api.props" v-if="api && api.props && api.props.length > 0"></PropsDescription>
          <ct-card v-else flat>No Props data</ct-card>
        </div>
      </v-tab-item>
      <v-tab-item class="v-tab-items">
        <div>
          <EventsDescription :propertyList="api.events" v-if="api && api.events && api.events.length > 0"></EventsDescription>
          <ct-card v-else flat>No Events data</ct-card>
        </div>
      </v-tab-item>
      <v-tab-item class="v-tab-items">
        <div>
          <SlotsDescription :propertyList="api.slots" v-if="api && api.slots && api.slots.length > 0"></SlotsDescription>
          <ct-card v-else flat>No Slots data</ct-card>
        </div>
      </v-tab-item>
    </ct-tabs>
  </div>
</template>
<script>
import { getAPI } from '@/utils/api'
import { CtTabs, CtTab } from 'clevertap-dashboard-components/components/Tabs'
import { CtCard } from 'clevertap-dashboard-components/components/Card'
import PropsDescription from './PropsDescription'
import EventsDescription from './EventsDescription'
import SlotsDescription from './SlotsDescription'

export default {
  components: {
    CtTabs,
    CtTab,
    CtCard,
    PropsDescription,
    EventsDescription,
    SlotsDescription
    // CtTabItem not able to solve this 😞
  },
  data () {
    return {
      api: null,
      verticalTabs: [
        { id: 'props', label: 'Props' },
        { id: 'events', label: 'Events' },
        { id: 'slots', label: 'Slots' }
      ],
      activeTab: null
    }
  },
  created () {
    getAPI(this.$route.path).then(r => { this.api = r })
  }
}
</script>
<style scoped>
.title {
  font-size: 16px !important;
  line-height: 24px;
  color: #434761;
  font-weight: bold;
  margin-bottom: 11px;
}
.vertical-tabs {
  justify-content: flex-start;
  width: 184px;
}
.v-tabs-items div {
  min-height: 144px;
  padding-left: 24px;
  background: #f9f9fb;
}
</style>
