import { render, staticRenderFns } from "./_CellBody.vue?vue&type=template&id=f033a5ac&"
import script from "./_CellBody.vue?vue&type=script&lang=js&"
export * from "./_CellBody.vue?vue&type=script&lang=js&"
import style0 from "./_CellBody.vue?vue&type=style&index=0&lang=scss&scopped=true&"


/* normalize component */
import normalizer from "!../../../docs/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports