<template>
  <div>
    <div>
      <h2>Neutral color palette</h2>
      <p>
        Neutrals have multiple tint and shades that allow for the appropriate level of hierarchy and accessibility across dashboard. Typically they are used for text and backgrounds.
      </p>
    </div>
    <div class="d-flex justify-space-around">
      <color-tile :rgb="'251, 251, 251'" :hex="'#FBFBFB'" :title="'blue-grey lighten-11'"></color-tile>
      <color-tile :rgb="'249, 249, 251'" :hex="'#F9F9FB'" :title="'blue-grey lighten-10'"></color-tile>
      <color-tile :rgb="'241, 242, 245'" :hex="'#F1F2F5'" :title="'blue-grey lighten-9'"></color-tile>
    </div>
    <div class="d-flex justify-space-around mt-5">
      <color-tile :rgb="'236, 237, 242'" :hex="'#ECEDF2'" :title="'blue-grey lighten-8'"></color-tile>
      <color-tile :rgb="'220, 222, 233'" :hex="'#DCDEE9'" :title="'blue-grey lighten-7'"></color-tile>
      <color-tile :rgb="'208, 210, 225'" :hex="'#D0D2E1'" :title="'blue-grey lighten-6'"></color-tile>
    </div>
    <div class="d-flex justify-space-around mt-5">
      <color-tile :rgb="'178, 181, 202'" :hex="'#B2B5CA'" :title="'blue-grey lighten-5'"></color-tile>
      <color-tile :rgb="'142, 148, 179'" :hex="'#8E94B3'" :title="'blue-grey lighten-4'" :dark="true"></color-tile>
      <color-tile :rgb="'121, 126, 162'" :hex="'#797EA2'" :title="'blue-grey lighten-3'" :dark="true"></color-tile>
    </div>
    <div class="d-flex justify-space-around mt-5">
      <color-tile :rgb="'99, 105, 143'" :hex="'#63698F'" :title="'blue-grey lighten-2'" :dark="true"></color-tile>
      <color-tile :rgb="'82, 88, 119'" :hex="'#525877'" :title="'blue-grey lighten-1'" :dark="true"></color-tile>
      <color-tile :rgb="'51, 54, 74'" :hex="'#33364A'" :title="'blue-grey darken-1'" :dark="true"></color-tile>
    </div>
    <div class="d-flex justify-space-around justify-start mt-5">
      <color-tile :rgb="'43, 46, 62'" :hex="'#2B2E3E'" :title="'blue-grey darken-2'" :dark="true"></color-tile>
    </div>
    <p>
    </p>
  </div>
</template>

<script>
import ColorTile from '../ColorTile.vue'
export default {
  components: {
    ColorTile
  }
}
</script>

<style scoped lang="scss">
  div {
    h2 {
      margin-top: 2.2rem;
      margin-left: 1.6rem;
    }
    p {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
      margin-left: 1.6rem;
    }
  }
</style>
