import SwitchSelect from './SwitchSelect'
import SwitchDisabled from './SwitchDisabled'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../../examplesImporter'

const exampleComponents = {
  SwitchSelect,
  SwitchDisabled
}

export { examplesArray, exampleComponents }
