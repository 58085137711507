<template>
  <div class="d-flex justify-space-around">
    <div class="text-center">
      <div>Small</div>
      <ct-button small color="secondaryBtn">Save</ct-button>
    </div>
    <div class="text-center">
      <div>Medium</div>
      <ct-button color="secondaryBtn">Save</ct-button>
    </div>
    <div class="text-center">
      <div>Large</div>
      <ct-button large color="secondaryBtn">Save</ct-button>
    </div>
  </div>
</template>

<script>
import CtButton from 'clevertap-dashboard-components/components/Button/CtButton'
export default {
  components: {
    CtButton
  }
}
</script>
