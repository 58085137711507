<template>
  <div class="ct-table" :class="tableClass">
    <div class="navigation px-6 d-flex align-center" v-if="navigationBar"><slot name="navigationbar">Default Navigation Bar (overwrite with #navigationbar)</slot></div>
    <div class="search px-6 d-flex align-center" v-if="searchBar">
      <slot name="searchbar">
        <ct-search-bar :placeholder="placeholder" :actionButtonHTML="actionButtonHTML" v-on="$listeners" v-model="_searchInput"/>
      </slot>
    </div>
    <div class="d-flex align-center blue-grey lighten-10">
      <div class="toolbar px-6 d-flex align-center" v-if="toolBar"><slot name="toolbar">Default Tool Bar (overwrite with #toolbar)</slot></div>
      <div v-if="colSelector" class="align-center d-flex px-6 flex-shrink-1">
        <ct-column-selector :columns="columns" @columnsChange="emitColumnsData" />
      </div>
    </div>

    <div class="datatable">
      <ct-grid
        ref="table-grid"
        :style="inlineStyle"
        v-bind="kendoAttributes"
        v-on="$listeners"
        :headerCellRender="_headerCellRender"
        :cellRender="_cellRender"
        :sort="_sort"
        :dataItems="result"
        :skip="_skip"
        :take="_take"
        :total="_total"
        :pageable="disablePagination ? false : {info: false, pageSizes: false, previousNext: true, type: 'numeric', buttonCount: 3}"
        @pagechange="pageChangeHandler"
      >
        <slot v-for="(_, name) in $slots" :name="name" v-slot:[name]/>
        <!-- Pass on all scoped slots -->
        <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope"/>
        </template>
        <template v-slot:internalHeaderTemplate="{props}" >
          <ct-cell-header-template
            v-bind="props"
            :header-items="columns"
            :data-items="result"
            :select-all="selectAll"
            :selected-field="selectedField"
            @selectAll="handleSelectAll"
            @sort="sortChangeHandler" />
        </template>
        <template v-slot:internalCellTemplate="{props}">
          <ct-cell-body-template
            :select-all="selectAll"
            :selected-field="selectedField"
            :expand-field="expandField"
            v-bind="props"
            @selection="handleSelection" />
        </template>
        <CtGridNoRecords>
          <div style="min-height: 200px;" class="no-more-records">
            <div v-if="!loading">
              <slot name="norecords">No Records Found</slot>
            </div>
          </div>
        </CtGridNoRecords>
      </ct-grid>
      <div class="loader d-flex align-center justify-center" v-if="loading"><ct-progress-circular value="45" color="primary" indeterminate></ct-progress-circular></div>
    </div>

    <div class="page-size-selector" v-if="hasTableItems && !disablePagination">
      <ct-table-page-size-selector @changePageSize="pageSizeHandler" :page-size="_take"/>
    </div>
  </div>
</template>
<script>

// Components
import KendoComponents from './kendo'
import CtTableTemplates from './templates'
import CtProgressCircular from '../../components/Progress/CtProgressCircular'

import { debounce } from '../../util/clevertap'
import { getAbsoluteWidth } from './utils'
export default {
  components: {
    ...KendoComponents,
    ...CtTableTemplates,
    CtProgressCircular
  },
  name: 'CtTable',
  props: {
    navigationBar: {
      // Show a navigation bar in the table?
      type: Boolean,
      default: false
    },

    searchBar: {
      // Show a searchbar in the table?
      type: Boolean,
      default: false
    },

    searchInput: {
      // Bind the searchbox value
      type: String,
      default: ''
    },

    toolBar: {
      // Show a toolbar in the table?
      type: Boolean,
      default: false
    },

    expandField: {
      type: String,
      default: ''
    },

    selectAll: {
      // Should all rows to be allowed to be selected?
      type: Boolean,
      default: false
    },

    selectedField: {
      type: String
    },

    sortable: {
      // Can the table be sorted?
      type: Boolean,
      default: false
    },

    loading: {
      // Display a default loader in the table
      type: Boolean,
      default: false
    },

    disablePagination: {
      // Do not show pagination. Instead display all records,
      type: Boolean,
      default: false
    },

    take: {
      // Number of rows to show
      type: Number,
      default: null
    },

    skip: {
      // Number of rows to skip
      type: Number,
      default: null
    },

    total: {
      // total number of items
      type: Number,
      default: null
    },

    sort: {
      type: Array,
      default: null
    },

    columns: {
      type: Array,
      default: () => ([])
    },

    dataItems: {
      type: Array,
      default: () => ([])
    },

    headerCellRender: {
      // Render a custom template sent by the developer
      type: String,
      default: null
    },

    cellRender: {
      type: String,
      default: null
    },

    // Search Bar props
    actionButtonHTML: {
      type: String,
      default: 'Add'
    },

    placeholder: {
      type: String,
      default: 'Search via... '
    },

    inlineStyle: {
      type: [String, Object],
      default: 'height: auto'
    },

    isPercentageWidth: {
      type: Boolean,
      default: false
    },

    colSelector: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      derivedSort: [],
      derivedSkip: 0,
      derivedTake: 10,
      result: []
    }
  },

  computed: {
    hasTableItems () {
      return Array.isArray(this.dataItems) && this.dataItems.length > 0
    },

    kendoAttributes () {
      return {
        ...this.$attrs,
        ...this.$props
      }
    },

    tableItemsCount () {
      return this.hasTableItems ? this.dataItems.length : 0
    },

    tableClass () {
      return {
        empty: !this.hasTableItems,
        'multi-select': this.selectAll,
        'expand-field': this.expandField
      }
    },

    lockedColumnsIndex () {
      const lockedColumnsIndex = []
      this.columns.forEach((col, index) => {
        if (col.locked) {
          lockedColumnsIndex.push(index)
        }
      })
      return lockedColumnsIndex
    },

    _searchInput: {
      get () {
        return this.searchBar && this.searchInput ? this.searchInput : ''
      },
      set (value) {}
    },

    // Kendo Mappers
    _sort () {
      return this.sort ? this.sort : this.derivedSort
    },

    _skip () {
      if (this.disablePagination) {
        return 0
      }
      return this.skip ? this.skip : this.derivedSkip
    },

    _take () {
      if (this.disablePagination) {
        return this.dataItems.length + 1
      }
      return this.take ? this.take : this.derivedTake
    },

    _total () {
      return this.total ? this.total : this.tableItemsCount
    },

    _headerCellRender () {
      return this.headerCellRender ? this.headerCellRender : 'internalHeaderTemplate'
    },

    _cellRender () {
      if (this.cellRender === '') {
        return null
      }
      return this.cellRender ? this.cellRender : 'internalCellTemplate'
    }
  },

  methods: {
    emitColumnsData (columns) {
      this.$emit('update:columns', columns)
    },

    resizeColumns () {
      const datatableWidth = document.querySelector('.k-grid-header-wrap')?.offsetWidth
      const absWidthColumns = this.columns.map(c => {
        if (!c.widthP) {
          return c
        }
        return { ...c, width: getAbsoluteWidth(datatableWidth, c.widthP) }
      })
      this.emitColumnsData(absWidthColumns)
    },

    pageSizeHandler (e) {
      if (this._take !== e) {
        this.$emit('pagechange', {
          page: {
            skip: 0,
            take: e
          }
        })
      }
      this.derivedTake = e
    },

    handleSelectAll (e) {
      this.$emit('selection-all', e)
    },

    handleSelection (e) {
      this.$emit('selection', e)
    },

    sortChangeHandler (e) {
      this.$emit('sortchange', e)
    },

    pageChangeHandler (e) {
      this.$emit('pagechange', e)
    },
    async addLockedClass () {
      if (this.lockedColumnsIndex.length > 0) {
        await this.$nextTick()
        const rows = this.$refs['table-grid'].$el.querySelectorAll('.k-master-row')
        const header = this.$refs['table-grid'].$el.querySelector('.k-grid-header-wrap')
        const headerCells = header.querySelectorAll('th')
        this.lockedColumnsIndex.forEach(c => {
          headerCells[c].classList.add('locked')
        })
        rows.forEach(row => {
          const cells = row.querySelectorAll('td')
          this.lockedColumnsIndex.forEach(c => {
            cells[c].classList.add('locked')
          })
        })
      }
    }
  },
  mounted () {
    this.result = this.dataItems
    this.addLockedClass()

    if (this.isPercentageWidth) {
      window.addEventListener('resize', debounce(this.resizeColumns, 300))
      // store initial percentage value in "widthP" attr
      // as we will update the "width" attr with absolute value on resize
      this.emitColumnsData(this.columns.map(c => {
        return { ...c, widthP: c.width }
      }))
      this.$nextTick(() => {
        this.resizeColumns()
      })
    }
  },
  destroyed () {
    document.removeEventListener('resize', debounce(this.resizeColumns, 300))
  },
  watch: {
    dataItems: {
      handler (newValue) {
        this.result = newValue
        this.addLockedClass()
      },
      deep: true
    }
  }
}
export const api = {
  props: [
    {
      name: 'Search Input',
      type: 'String',
      default: ' ',
      description: 'Bind the searchbox value'
    },
    {
      name: 'Exapnd Field',
      type: 'String',
      default: ' ',
      description: ''
    },
    {
      name: 'Select All',
      type: 'Boolean',
      default: 'false',
      description: 'All rows are selected'
    },
    {
      name: 'Selected Field',
      type: 'String',
      default: ' ',
      description: ' A single field is selected'
    },
    {
      name: 'Sortable',
      type: 'Boolean',
      default: 'false',
      description: 'Table can be sorted'
    },
    {
      name: 'Loading',
      type: 'Boolean',
      default: 'false',
      description: 'Display a default loader in the table'
    },
    {
      name: 'disablePagination',
      type: 'Boolean',
      default: 'false',
      description: 'Do not show pagination. Instead display all records,'
    },
    {
      name: 'Take',
      type: 'Number',
      default: 'null',
      description: 'Number of rows to show'
    },
    {
      name: 'Skip',
      type: 'Number',
      default: 'null',
      description: 'Number of rows to skip'
    },
    {
      name: 'total',
      type: 'Number',
      default: 'null',
      description: 'total number of items'
    },
    {
      name: 'Sort',
      type: 'Array',
      default: 'null',
      description: 'How to sort'
    },
    {
      name: 'columns',
      type: 'Array',
      default: 'Empty array',
      description: 'Array of Column header and types'
    },
    {
      name: 'headerCellRender',
      type: 'String',
      default: 'bull',
      description: 'Render a custom template sent by the developer'
    },
    {
      name: 'Placeholder',
      type: 'String',
      default: 'Search via..',
      description: ''
    },
    {
      name: 'navigationBar',
      type: 'Boolean',
      default: 'false',
      description: 'Show a navigation bar in the table'
    },
    {
      name: 'searchBar',
      type: 'Boolean',
      default: 'False',
      description: 'Show a searchbar in the table'
    },
    {
      name: 'toolBar',
      type: 'Boolean',
      default: 'False',
      description: 'Show a toolbar in the table'
    }
  ],
  events: [
    {
      name: 'pageSizeHandler',
      type: 'emit',
      description: 'Calculates the number of pages of tables using skip&takes'
    },
    {
      name: 'handleSelectAll',
      type: 'emit',
      description: 'Emits an event to select all rows'
    },
    {
      name: 'handleSelection',
      type: 'emit',
      description: 'Emits an event to handle all the selected rows'
    },
    {
      name: 'sortChangeHandler',
      type: 'emit',
      description: 'Emits an event to handle how the sort changes'
    },
    {
      name: 'pageChangeHandler',
      type: 'emit',
      description: 'Emits an event when the page changes'
    }
  ],
  slots: [
    {
      name: 'navigationbar',
      description: 'Slot for navigation-bar'
    },
    {
      name: 'SearchBar',
      description: 'Slot for the Searchbar'
    },
    {
      name: 'Toolbar',
      description: 'Slot for the toolbar'
    },
    {
      name: 'No Records',
      description: 'Slot for when the table has no records'
    }
  ]
}
</script>
<style lang="scss">
.ct-table {
  box-shadow: 0 0px 10px 0 rgba(0,0,0,0.08);
  height: calc(100% - 72px); // No shadow on pagination
  position: relative;

  &.empty {
    height: auto;
  }

  &.expand-field {
    .k-grid-header-wrap {
      border: 0px;

      th {
        &:first-child {
          .select-all {
            display: none;
          }
        }
        &:nth-child(2) {
          .select-all {
            display: block;
          }
        }
      }
    }
  }

  &:not(.multi-select) {
    .k-state-selected {
      background-color: rgba(18, 107, 255, 0.06) !important;
    }
  }

  &.empty {
    .k-pager-wrap {
      display: none;
    }
  }

  .navigation {
    width: 100%;
    height: 48px;
    background-color: #ecf4ff;
  }

  .search {
    width: 100%;
    height: 60px;
    background-color: #fff;

    .searchbox {
      width: 240px;
      height: 100%;
    }
  }

  .toolbar {
    height: 52px;
    width: 100%;
  }

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.1);
  }

  .k-grid {
    border: 0;
    background-color: transparent;
    color: rgba(map-get($blue-grey, 'base'), 1);

    table {

      th {
        background-color: rgba(map-get($blue-grey, 'lighten-9'), 1);
        border-right: 1px solid #ECEDF2;
        border-bottom: 0px solid;

        &:last-child {
          border-right-width: 0;
        }

        &[aria-sort="descending"] {
          .sort-manager {
            transform: rotate(180deg)
          }
        }
        &.k-hierarchy-cell {
          background-color: rgba(map-get($blue-grey, 'lighten-9'), 1) !important;
          border-right: 0px;
        }

        &.locked {
          background-color: #ecedf1 !important;
        }
      }

      tr {

        &:hover {
          background-color: initial;
          // color: rgba(map-get($blue-grey, 'base'), 1);
        }

        &.k-master-row {
          background-color: #fff;
          & > td.k-hierarchy-cell {
            background-color: #fff !important;
            border-right: 0px;

            a.k-icon {
              color: var(--v-anchor-base) !important;
              margin-left: 12px;
            }
          }

          &.k-alt {
            background-color: rgba(map-get($blue-grey, 'lighten-10'), 1);
            & > td.k-hierarchy-cell {
              background-color: rgba(map-get($blue-grey, 'lighten-10'), 1) !important;
            }

            .locked {
              background-color: #f4f4f7 !important;
            }
          }

          .locked {
            background-color: #fafafb !important;
          }
        }

        &.k-detail-row {
          box-shadow: 0 3px 6px 0 rgba(67,71,97,0.12) inset, 0px -3px 6px 0 rgba(67,71,97,0.12) inset;
          & > td.k-hierarchy-cell {
            border-right: 0px;
          }
          & > td.k-detail-cell {
            padding: 24px !important;
          }
        }

        &.k-grid-norecords {
          td {
            &, &:hover {
              color: rgba(map-get($blue-grey, 'lighten-2'), 1);
            }
            .no-more-records {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      td {
        border-right: 1px solid #ECEDF2;
        border-bottom: 0px solid;

        &:last-child {
          border-right-width: 0;
        }
      }
    }

    a {
      color: var(--v-anchor-base);
    }
  }

  .k-sorted {
    color: rgba(map-get($blue-grey, 'base'), 1);
    .sort-manager {
      opacity: 1;
      margin-left: 8px;
    }
  }

  .k-pager-numbers {
    padding-left: 0;
    .k-link {
      width: calc(16px + 1.4285714286em);
      height: calc(16px + 1.4285714286em);
    }
    .k-state-selected {
      background-color: transparent;
      color: var(--v-secondary-base);
      cursor: default;
    }
  }

  .k-grid-pager {
    justify-content: center;
    border: 0;
    background-color: transparent;
    padding: 18px 10px;
  }

  .k-grid-header {
    border: 0;
    color: rgba(map-get($blue-grey, 'lighten-2'), 1);
  }

  .k-grid-header-wrap {
    border: 0px;

    table {
      width: 100% !important;
    }

    th {
      &:first-child {
        .select-all {
          display: block;
        }
      }
    }
  }

  .k-grid-container {

    table {
      width: 100% !important;
    }
  }

  .page-size-selector {
    height: 0;
    float: right;
    position: relative;
    bottom: 56px;
  }

  .k-hierarchy-cell {
    .k-plus,
    .k-minus {
      background-color: transparent;
      transition: background-color 0.2s cubic-bezier(0.4, 0, 0.6, 1);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: transparentize($color: #126bff, $amount: 0.92)
      }
    }
  }
}
</style>
<style scoped>
>>> .k-grid-table .ct-button.v-btn--icon {
  width: 24px;
  height: 24px;
}
</style>
