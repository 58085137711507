<template>
  <ct-navigation-drawer
    :width="width"
    :key="appId"
    clipped
    :app="mainApp"
    class="navigation-drawer core-navigation-drawer"
    :class="{'collapsed': miniVariant, 'hover-nav': !mainApp, 'has-app-nav': hasAppNav}"
    :style="navStyle"
    fixed
    :expand-on-hover="!mainApp"
    :mini-variant="miniVariant"
    @update:mini-variant="updateMiniVariant"
    :mini-variant-width="64">
    <div class="navigation-drawer--main-nav">
      <template v-for="(item, index) in mainNavItems">
        <ct-navigation-container :key="index" :navItems="item.children" :sub-header="item.subHeader"
                                 :sub-header-short="item.subHeaderShort" :collapsed="miniVariant"/>
      </template>
      <ct-tooltip right v-if="mainApp">
        <template v-slot:activator="{ on }">
          <div v-on="on" v-if="needsDrawerControl" class="navigation-toggle" @click.capture="handleDrawerClick" :class="{'open': !drawer}">
            <ct-icon color="#1A1B2A">ctleftnavicon-Return</ct-icon>
          </div>
        </template>
        <span>{{drawer ? 'Collapse' : 'Expand'}}</span>
      </ct-tooltip>
    </div>
    <template #prepend>
      <div class="header pa-3">
        <svg
          data-v-7381f048
          width="111px"
          height="24px"
          viewBox="0 0 111 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          class="main"
        >
          <g
            data-v-7381f048
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              data-v-7381f048
              id="LNSO-01"
              transform="translate(-47.000000, -21.000000)"
              fill-rule="nonzero"
            >
              <g data-v-7381f048 id="Group-4" transform="translate(47.000000, 21.000000)">
                <path
                  data-v-7381f048
                  d="M12.9320276,12.32 L15.0181747,12.32 L15.0181747,13.0144 C15.0181747,15.4997333 14.5828806,17.0506667 13.7122923,17.6672 C12.8417041,18.2837333 10.6652335,18.5930667 7.18288057,18.5952 C4.06617469,18.5952 2.08558645,18.0501333 1.24111586,16.96 C0.396645276,15.8698667 -0.0168841355,13.3066667 0.000527629234,9.2704 C0.000527629234,6.1088 0.0962923351,4.12053333 0.287821747,3.3056 C0.479351159,2.49066667 1.02455704,1.76213333 1.92343939,1.12 C2.97685116,0.373333333 5.25235116,1.69464442e-12 8.74993939,1.69464442e-12 C11.1201159,1.69464442e-12 12.7198217,0.360533333 13.549057,1.0816 C14.3782923,1.80266667 14.7896453,3.19573333 14.7831159,5.2608 L14.7831159,5.7536 L12.7002335,5.7536 L12.7002335,5.1936 C12.7002335,3.72373333 12.457557,2.77973333 11.9722041,2.3616 C11.4868512,1.94346667 10.3801159,1.73546667 8.65199822,1.7376 C5.65499822,1.7376 3.82458645,1.98293333 3.16076292,2.4736 C2.49693939,2.96426667 2.17046881,4.3264 2.18135116,6.56 C2.18135116,11.68 2.39899822,14.6805333 2.83429234,15.5616 C3.26958645,16.4426667 4.79311586,16.8832 7.40488057,16.8832 C9.87517469,16.8832 11.4172041,16.6922667 12.0309688,16.3104 C12.6447335,15.9285333 12.9516159,14.9685333 12.9516159,13.4304 L12.9320276,12.32 Z"
                  id="Shape"
                  fill="#313447"
                />
                <polygon
                  data-v-7381f048
                  id="Shape"
                  fill="#313447"
                  points="19.2002629 0.1856 19.2002629 18.4256 17.29041 18.4256 17.29041 0.1856 19.1969982 0.1856"
                />
                <path
                  data-v-7381f048
                  d="M31.6126747,14.56 L33.5714982,14.56 L33.5714982,15.0272 C33.5714982,16.3541333 33.1601453,17.2821333 32.3374394,17.8112 C31.5147335,18.3402667 30.07391,18.6048 28.0149688,18.6048 C25.6208512,18.6048 24.0472629,18.1781333 23.2942041,17.3248 C22.5411453,16.4714667 22.1667923,14.6634667 22.1711453,11.9008 C22.1711453,9.3536 22.5476747,7.64693333 23.3007335,6.7808 C24.0537923,5.91466667 25.5294394,5.4784 27.7276747,5.472 C30.1217923,5.472 31.6932041,5.85066667 32.44191,6.608 C33.1906159,7.36533333 33.5627923,8.95893333 33.5584394,11.3888 L33.5584394,12.3904 L24.1854688,12.3904 C24.1854688,14.4021333 24.4031159,15.6821333 24.83841,16.2304 C25.2737041,16.7786667 26.3020865,17.0549333 27.923557,17.0592 C29.4579688,17.0592 30.4547923,16.928 30.9140276,16.6656 C31.3732629,16.4032 31.6028806,15.8357333 31.6028806,14.9632 L31.6028806,14.56 L31.6126747,14.56 Z M31.6126747,10.928 L31.6126747,10.3264 C31.6126747,8.94613333 31.3808806,8.04906667 30.9172923,7.6352 C30.4537041,7.22133333 29.4525276,7.01333333 27.9137629,7.0112 C26.3684688,7.0112 25.3672923,7.25333333 24.9102335,7.7376 C24.4531747,8.22186667 24.223557,9.28853333 24.2213806,10.9376 L31.6126747,10.9376 L31.6126747,10.928 Z"
                  id="Shape"
                  fill="#313447"
                />
                <polygon
                  data-v-7381f048
                  id="Shape"
                  fill="#313447"
                  points="46.2189688 5.648 41.8573217 18.416 39.1182335 18.416 34.8022923 5.648 36.7611159 5.648 39.0627335 12.592 39.7842335 14.768 40.1107041 15.8624 40.466557 16.9568 40.522057 16.9568 40.8485276 15.8752 41.1749982 14.7808 41.8703806 12.6176 44.0773217 5.6448 46.2026453 5.6448"
                />
                <path
                  data-v-7381f048
                  d="M56.86191,14.56 L58.8207335,14.56 L58.8207335,15.0272 C58.8207335,16.3541333 58.4093806,17.2821333 57.5866747,17.8112 C56.7639688,18.3402667 55.322057,18.6048 53.2609394,18.6048 C50.8668217,18.6048 49.2932335,18.1781333 48.5401747,17.3248 C47.7871159,16.4714667 47.4127629,14.6666667 47.4171159,11.9104 C47.4171159,9.3632 47.7936453,7.65653333 48.5467041,6.7904 C49.2997629,5.92426667 50.77541,5.488 52.9736453,5.4816 C55.3677629,5.4816 56.9391747,5.86026667 57.6878806,6.6176 C58.4365865,7.37493333 58.8087629,8.96853333 58.80441,11.3984 L58.80441,12.4 L49.4347041,12.4 C49.4347041,14.4117333 49.6523512,15.6917333 50.0876453,16.24 C50.5229394,16.7882667 51.5513217,17.0645333 53.1727923,17.0688 C54.7072041,17.0688 55.7040276,16.9376 56.1632629,16.6752 C56.6224982,16.4128 56.8521159,15.8453333 56.8521159,14.9728 L56.8521159,14.56 L56.86191,14.56 Z M56.86191,10.928 L56.86191,10.3264 C56.86191,8.94613333 56.6301159,8.04906667 56.1665276,7.6352 C55.7029394,7.22133333 54.7039394,7.0144 53.1695276,7.0144 C51.6242335,7.0144 50.623057,7.25653333 50.1659982,7.7408 C49.7089394,8.22506667 49.4793217,9.29173333 49.4771453,10.9408 L56.8749688,10.9408 L56.86191,10.928 Z"
                  id="Shape"
                  fill="#313447"
                />
                <path
                  data-v-7381f048
                  d="M62.0136159,5.648 L63.9202041,5.648 L63.7308512,7.1168 L63.7732923,7.1584 C64.5263512,5.95733333 65.7712923,5.3568 67.5081159,5.3568 C69.9022335,5.3568 71.0992923,6.56746667 71.0992923,8.9888 L71.0992923,9.8688 L69.2188217,9.8688 L69.2612629,9.5488 C69.289557,9.2096 69.3037041,8.9824 69.3037041,8.8672 C69.3037041,7.55946667 68.5767629,6.90453333 67.1228806,6.9024 C64.9964688,6.9024 63.9332629,8.1888 63.9332629,10.7616 L63.9332629,18.4192 L62.0266747,18.4192 L62.0266747,5.648 L62.0136159,5.648 Z"
                  id="Shape"
                  fill="#313447"
                />
                <polygon
                  data-v-7381f048
                  id="Shape"
                  fill="#FF893C"
                  points="80.3122923 3.2864 80.3122923 18.4192 76.7831453 18.4192 76.7831453 3.2864 71.4127041 3.2864 71.4127041 0.1856 85.8688217 0.1856 85.8688217 3.2832 80.3188217 3.2832"
                />
                <path
                  data-v-7381f048
                  d="M87.2824394,9.3504 L84.0928217,9.3504 C84.0928217,7.81866667 84.4562923,6.79466667 85.1832335,6.2784 C85.9101747,5.76213333 87.34991,5.504 89.5024394,5.504 C91.8464982,5.504 93.432057,5.824 94.2591159,6.464 C95.0861747,7.104 95.4975276,8.30933333 95.4931747,10.08 L95.4931747,18.4192 L92.3721159,18.4192 L92.5222923,16.6688 L92.4406747,16.6688 C91.8421453,17.9488 90.4568217,18.5888 88.2847041,18.5888 C85.1331747,18.5888 83.55741,17.2757333 83.55741,14.6496 C83.55741,12.0234667 85.1701747,10.6965333 88.3957041,10.6688 C90.5482335,10.6688 91.8541159,11.1541333 92.3133512,12.1248 L92.3688512,12.1248 L92.3688512,10.0288 C92.3688512,9.03253333 92.1914688,8.3712 91.8367041,8.0448 C91.4819394,7.7184 90.7549982,7.55626667 89.6558806,7.5584 C88.0735865,7.5584 87.2824394,8.15573333 87.2824394,9.3504 Z M89.3685865,12.704 C87.6056453,12.704 86.7241747,13.3002667 86.7241747,14.4928 C86.7241747,15.3205333 86.90591,15.8634667 87.2693806,16.1216 C87.6328512,16.3797333 88.3946159,16.5088 89.5546747,16.5088 C91.452557,16.5088 92.4014982,15.8805333 92.4014982,14.624 C92.4014982,13.3674667 91.3905276,12.7264 89.3685865,12.7008 L89.3685865,12.704 Z"
                  id="Shape"
                  fill="#FF893C"
                />
                <path
                  data-v-7381f048
                  d="M98.6077041,5.648 L101.780998,5.648 L101.660204,7.5456 L101.728763,7.5456 C102.481822,6.16533333 103.885645,5.4752 105.940234,5.4752 C107.838116,5.4752 109.143998,5.936 109.857881,6.8576 C110.571763,7.7792 110.928704,9.46026667 110.928704,11.9008 C110.928704,14.448 110.573939,16.1930667 109.86441,17.136 C109.154881,18.0789333 107.846822,18.5546667 105.940234,18.5632 C103.896528,18.5632 102.561263,17.8954667 101.934439,16.56 L101.878939,16.56 L101.878939,23.8944 L98.6142335,23.8944 L98.6142335,5.6544 L98.6077041,5.648 Z M104.751881,7.904 C103.561351,7.904 102.784351,8.1664 102.420881,8.6912 C102.05741,9.216 101.875675,10.3285333 101.875675,12.0288 C101.875675,13.6842667 102.070469,14.784 102.460057,15.328 C102.849645,15.872 103.644057,16.144 104.843292,16.144 C106.059939,16.144 106.849998,15.8858667 107.213469,15.3696 C107.576939,14.8533333 107.758675,13.7450667 107.758675,12.0448 C107.758675,10.2997333 107.574763,9.17333333 107.206939,8.6656 C106.839116,8.15786667 106.020763,7.90506667 104.751881,7.9072 L104.751881,7.904 Z"
                  id="Shape"
                  fill="#FF893C"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <CompDesignSection></CompDesignSection>
    </template>
  </ct-navigation-drawer>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import navigationMixin from 'clevertap-dashboard-components/layouts/NavBar/navigationMixin'
import CompDesignSection from '@/components/CompDesignSection'

export default {
  name: 'Navigation',
  components: {
    CompDesignSection
  },
  mixins: [ navigationMixin ],
  computed: {
    ...mapState('site', ['mainApp', 'mainNavItems', 'nagbarQueue', 'navItems', 'appId']),
    ...mapState('account', ['integrationChecklistStatus', 'isSelfServe']),
    width () {
      return this.mainApp ? 240 : 240
    },
    hasNavItems () {
      return !!this.mainNavItems && this.mainNavItems.length > 0
    },
    hasAppNav () {
      return !!this.navItems && this.navItems.length > 0
    },
    hasSecondaryNavItems () {
      return !!this.secondaryNavItems && this.secondaryNavItems.length > 0
    },
    hasIconStripNavItems () {
      return !!this.iconStripNavItems && this.iconStripNavItems.length > 0
    },
    navStyle () {
      if (this.mainApp) {
        return null
      }
      const initialOffset = this.isSelfServe && this.integrationChecklistStatus && this.integrationChecklistStatus !== 'COMPLETED' ? 100 : 52
      let topOffset = initialOffset
      if (this.nagbarQueue.length > 0) {
        topOffset += 48
      }
      return { top: `${topOffset}px`, maxHeight: `calc(100% - ${topOffset}px)` }
    }
  },
  methods: {
    ...mapMutations('site', {
      updateMatchedNavItem: 'UPDATE_MATCHED_NAV_ITEM',
      updateMainMatchedNavItem: 'UPDATE_MAIN_MATCHED_NAV_ITEM'
    }),
    updateMenuOp () {
      this.miniVariant = false
    },
    updateDrawerState () {
      if (this.mainApp) {
        this.setDrawer(!this.drawer)
      }
    },
    updateMiniVariant (value) {
      if (!this.mainApp) {
        this.miniVariant = value
      }
    }
  },
  watch: {
    $route (to) {
      const href = this.$router.resolve(to).href
      this.updateMatchedNavItem(href)
      this.updateMainMatchedNavItem(href)
    },
    _miniVariant: {
      handler (newVal) {
        if (this.mainApp) {
          this.miniVariant = newVal
        }
      },
      immediate: true
    },
    mainApp: {
      handler (value) {
        if (value) {
          this.$nextTick(() => {
            this.miniVariant = this._miniVariant
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
