<template>
  <div class="d-flex justify-space-around">
    <ct-unified-select variant="autocomplete" placeholder="Search" is-search :items="['Foo', 'Bar', 'Default Value', 'Buzz']" :hint="selectboxHint" :label="selectboxLabel"  ></ct-unified-select>
  </div>
</template>

<script>
import CtUnifiedSelect from 'clevertap-dashboard-components/components/Select/CtUnifiedSelect'
export default {
  components: {
    CtUnifiedSelect
  },
  data: () => ({
    itemsObject: [
      { id: 0, customText: 'Foo', customValue: 'foo' },
      { id: 1, customText: 'Bar', customValue: 'bar' },
      { id: 2, customText: 'Event', customValue: 'event' },
      { id: 3, customText: 'Buzz', customValue: 'buzz' }
    ]
  })
}
</script>
