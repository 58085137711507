<template>
  <div class="container-box">
    <textarea
      ref="copySelection"
      readonly
      class="t-14 font-weight-regular px-10 py-6"
      v-model="code"
    ></textarea>
    <div class="buttonoverlap">
      <ct-icon @click="copyText" :size="32" color="#126BFF" v-if="isCopy">cticon-copy</ct-icon>
      <ct-icon :size="32" color="#126BFF" v-else>cticon-tick-filled</ct-icon>
    </div>
  </div>
</template>

<script>
import CtIcon from 'clevertap-dashboard-components/components/Icon'
export default {
  name: 'CopyCodeSection',
  components: {
    CtIcon
  },
  props: {
    code: {
      type: String,
      required: true
    },
    delay: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      isCopy: true
    }
  },
  mounted () {
    this.resizeTextArea()
  },
  methods: {
    copyText () {
      let copySelectionElement = this.$refs.copySelection
      this.isCopy = false
      setTimeout(() => { this.isCopy = true }, this.delay)
      copySelectionElement.select()
      document.execCommand('copy')
      this.clearSelection(copySelectionElement)
    },
    resizeTextArea () {
      let copySelectionElement = this.$refs.copySelection
      copySelectionElement.style.height = `${copySelectionElement.scrollHeight}px`
    },
    clearSelection (copySelectionElement) {
      if (window.getSelection) {
        if (window.getSelection().empty) { // Chrome
          window.getSelection().empty()
        }
        if (window.getSelection().removeAllRanges) { // Firefox
          window.getSelection().removeAllRanges()
          copySelectionElement.blur()
        }
      } else if (document.selection) { // IE safari
        document.selection.empty()
      }
    }
  }
}
</script>

<style scoped>
textarea {
  background-color: #33364a;
  color: #fff;
  overflow-y: hidden;
  width: 100%;
  resize: none;
  font-family: Courier;
  border-radius: 4px;
}
.container-box {
  position: relative;
}
.buttonoverlap {
  display: none;
}
.container-box:hover .buttonoverlap {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: inline-block;
  opacity: 1;
  border: 1px solid #126bff;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
}
.container-box {
  margin: 0;
  padding: 0;
}
</style>
