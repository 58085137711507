import store from 'clevertap-dashboard-components/store'
import components from './components'
import navItems from '@/router/navigation'

store.registerModule('components', components)
store.commit('site/SET_MAIN_APP', true)
store.commit('site/SET_APP_ID', 'docs')
store.commit('site/SET_MAIN_NAV_ITEMS', navItems)

export default store
