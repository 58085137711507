<template>
  <ct-unified-select
    placeholder="Some Placeholder Text"
    :items="items"
    v-model="val"
    multiple
  ></ct-unified-select>
</template>

<script>
import CtUnifiedSelect from 'clevertap-dashboard-components/components/Select/CtUnifiedSelect'

export default {
  components: {
    CtUnifiedSelect
  },
  data: () => ({
    val: [],
    items: [
      {
        header: 'Category 1'
      },
      {
        text: 'Item 2',
        value: '2'
      }, {
        text: 'Default Value',
        value: '3'
      }, {
        text: 'Item 4',
        value: '4'
      }, {
        header: 'Category 2'
      }, {
        text: 'Item 2',
        value: '5'
      }
    ]
  })
}
</script>
