<template>
  <div class="d-flex">
      <ct-tag type="draft">Draft</ct-tag>
  </div>
</template>

<script>
import CtTag from 'clevertap-dashboard-components/components/Tag/CtTag'
export default {
  components: {
    CtTag
  }
}
</script>
