<template>
  <div class="d-flex justify-space-around">
    <div class="text-center">
      <v-btn-toggle>
        <ct-button class="secondary">
          <ct-icon color="white" small class="mr-1">cticon-filter</ct-icon>
          <span>Create</span>
        </ct-button>
        <ct-button class="secondary">
          <ct-icon color="white" small class="mr-1">cticon-Reorder</ct-icon>
          <span>Create</span>
        </ct-button>
      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
import CtButton from 'clevertap-dashboard-components/components/Button/CtButton'
import CtIcon from 'clevertap-dashboard-components/components/Icon/CtIcon'

export default {
  components: {
    CtButton,
    CtIcon
  }
}
</script>
