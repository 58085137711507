<template>
    <div class="text-center">
      <ct-button v-if="chip1 === false" @click="chip1 = true">Reset</ct-button>
      <ct-chip :active="chip1" close @click:close=changeChip1>Default</ct-chip>
    </div>
</template>

<script>
import CtButton from 'clevertap-dashboard-components/components/Button/CtButton.vue'
import CtChip from '../../../../../components/Chip/CtChip.vue'

export default {
  components: {
    CtButton,
    CtChip
  },
  data () {
    return {
      chip1: true
    }
  },
  methods: {
    changeChip1 () {
      this.chip1 = false
    }
  }
}
</script>
