<template>
  <ct-dialog v-model="_dialog" :width="width" :persistent="persistent" :max-width="maxWidth" :content-class="contentClass">
    <ct-card>
      <ct-card-title class="headline" primary-title>
        <slot name="title">{{title}}</slot>
        <ct-icon class="t-16" @click="close">cticon-close</ct-icon>
      </ct-card-title>
      <ct-divider></ct-divider>
      <ct-card-text>
        <slot name="modal-body"></slot>
      </ct-card-text>
      <ct-card-actions class="blue-grey lighten-9">
        <slot name="modal-footer"></slot>
      </ct-card-actions>
    </ct-card>
  </ct-dialog>
</template>
<script>
    import CtDialog from '../Dialog/CtDialog'
    import CtDivider from '../Divider/CtDivider'
    import CtCard from '../Card/CtCard'
    import CtCardTitle from '../Card/CtCardTitle'
    import CtCardText from '../Card/CtCardText'
    import CtCardActions from '../Card/CtCardActions'
    import CtIcon from '../Icon/CtIcon'
    export default {
      name: 'CtModal',
      components: {
        CtDialog,
        CtDivider,
        CtCard,
        CtCardTitle,
        CtCardText,
        CtCardActions,
        CtIcon
      },
      props: {
        modaltype: {
          type: String,
          default: 'large'
        },
        dialog: {
          type: Boolean,
          default: false
        },
        persistent: {
          type: Boolean,
          default: true
        },
        title: {
          type: String,
          default: ''
        },
        maxWidth: {
          type: [String, Number],
          default: ''
        },
        contentClass: {
          type: String,
          default: ''
        }
      },
      computed: {
        width () {
          switch (this.modaltype) {
            case 'small':
              return '400px'
            case 'large':
              return '560px'
            default :
              return this.modaltype
          }
        },
        _dialog: {
          get () {
            return this.dialog
          },
          set (value) {
            this.$emit('modalToggle', value)
          }
        }
      },
      methods: {
        close () {
          this.$emit('close')
          this._dialog = false
        }
      },
      model: {
        prop: 'dialog',
        event: 'modalToggle'
      }
    }
    export const api = {
      props: [
        {
          name: 'modaltype',
          type: 'String',
          default: 'large',
          description: 'Accpets value of large/small and sets the width of the modal accordingly'
        },
        {
          name: 'dialog',
          type: 'Boolean',
          default: 'false',
          description: 'Controls when to show the modal on the screen'
        },
        {
          name: 'presistant',
          type: 'Boolean',
          default: 'true',
          description: 'Clicking outside of the element or pressing esc key will not deactivate it.'
        },
        {
          name: 'title',
          type: 'String',
          default: '',
          description: 'Title of the modal'
        }
      ],
      events: [
        {
          name: 'Close',
          type: '',
          description: 'Closes the Modal'
        },
        {
          name: 'Click:outside',
          type: 'MouseEvent',
          description: 'Event that fires when clicking outside an active dialog.'
        },
        {
          name: 'Input',
          type: 'Boolean',
          description: 'The updated bound model'
        },
        {
          name: 'Keydown',
          type: 'KeyBoardEvent',
          description: 'Event that fires when key is pressed. If dialog is active and not using the persistent prop, the esc key will deactivate it.'
        }
      ],
      slots: [
        {
          name: 'Title',
          description: 'Title of the modal'
        },
        {
          name: 'Modal-Body',
          description: 'The contents of the modal'
        },
        {
          name: 'Modal-Footer',
          description: 'The footer content of the modal'
        },
        {
          name: 'Activator',
          type: '{on:\n{[eventName]:eventHandler},\nvalue: boolean}',
          description: 'When used, will activate the component when clicked (or hover for specific components). This manually stops the event propagation. Without this slot, if you open the component through its model, you will need to manually stop the event propagation'
        }
      ]
    }
</script>

<style scoped lang="scss">
  .v-dialog {
    & .v-card {
      & .v-card__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 24px;
        font-size: 18px!important;
        font-weight: 600;
        line-height: 18px!important;
      }
      & .v-card__text {
        padding: 24px;
        max-height: 292px;
        min-height: 84px;
        overflow: auto;
      }
      & .v-card__actions {
        padding: 8px 16px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
</style>
