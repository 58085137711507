export async function getGuidelines (path) {
  let guidelines = null
  switch (path.replace('/guidelines', '')) {
    case '/dropdowns/single-select':
      guidelines = await import('@/components/dropdown/single-select/Guidelines.md')
      break
    case '/dropdowns/multi-select':
      guidelines = await import('@/components/dropdown/multi-select/Guidelines.md')
      break
    case '/selection-controls/checkbox':
      guidelines = await import('@/components/selection-controls/checkbox/Guidelines.md')
      break
    case '/selection-controls/radio':
      guidelines = await import('@/components/selection-controls/radio/Guidelines.md')
      break
    case '/selection-controls/switch':
      guidelines = await import('@/components/selection-controls/switch/Guidelines.md')
      break
    case '/buttons/SimpleButton':
      guidelines = await import('@/components/buttons/simple-button/Guidelines.md')
      break
    case '/typography':
      guidelines = await import('@/components/typography/Guidelines.md')
      break
    case '/nags':
      guidelines = await import('@/components/nags/Guidelines.md')
      break
    case '/snackbar':
      guidelines = await import('@/components/snackbar/Guidelines.md')
      break
    case '/alerts/AlertGeneric':
      guidelines = await import('@/components/alerts/Guidelines.md')
      break
    case '/tooltip':
      guidelines = await import('@/components/tooltip/Guidelines.md')
      break
    case '/colors':
      guidelines = await import('@/components/color/Guidelines.md')
      break
    case '/Search/Search':
      guidelines = await import('@/components/Search/Guidelines.md')
      break
    case '/modal':
      guidelines = await import('@/components/modal/Guidelines.md')
      break
    case '/grid-system':
      guidelines = await import('@/components/grid-system/Guidelines.md')
      break
    case '/buttons/ButtonGroup':
      guidelines = await import('@/components/buttons/button-group/Guidelines.md')
      break
    case '/chips':
      guidelines = await import('@/components/chips/Guidelines.md')
      break
    case '/card':
      guidelines = await import('@/components/Cards/Guidelines.md')
      break
    case '/textArea':
      guidelines = await import('@/components/textArea/Guidelines.md')
      break
    case '/tabs':
      guidelines = await import('@/components/tabs/Guidelines.md')
      break
    case '/tags':
      guidelines = await import('@/components/tag/Guidelines.md')
      break
    case '/timePicker':
      guidelines = await import('@/components/TimePicker/Guidelines.md')
      break
    case '/tables':
      guidelines = await import('@/components/Tables/Guidelines.md')
      break
    default:
      return null
  }
  return guidelines && guidelines.default ? guidelines.default : null
}
