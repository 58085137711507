<template>
    <div class="d-flex justify-space-around">
      <ct-row>
        <p>Breadcrumbs with Disabled Links
          <ct-breadcrumbs :items=items1 divider="-">
          </ct-breadcrumbs>
        </p>
      </ct-row>
      <ct-row>
        <p>Breadcrumbs with Large Link
          <ct-breadcrumbs :items=items divider=">" large></ct-breadcrumbs>
        </p>
      </ct-row>
    </div>
</template>

<script>
import CtBreadcrumbs from 'clevertap-dashboard-components/components/Breadcrumbs/CtBreadcrumbs.vue'
import CtRow from 'clevertap-dashboard-components/components/Grid/GridSystem/CtRow.vue'
export default {
  components: {
    CtBreadcrumbs,
    CtRow
  },
  data: () => ({
    items1: [
      {
        text: 'Link 1',
        disabled: true,
        href: '#'
      },
      {
        text: 'Link 2',
        disabled: true,
        href: '#'
      },
      {
        text: 'Link 3',
        disabled: true,
        href: '#'
      }
    ],
    items: [
      {
        text: 'Link 1',
        disabled: false,
        href: '#'
      },
      {
        text: 'Link 2',
        disabled: false,
        href: '#'
      },
      {
        text: 'Link 3',
        disabled: false,
        href: '#'
      }
    ]
  })
}

</script>
