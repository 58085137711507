<template>
  <SGWrapper>
    <div class="title">Usage examples</div>
    <code-layout v-for="(example, index) in examplesArray" :heading="example.name" :displayCode="example.content" :key="index">
      <div class="example text-center">
        <img :src="exampleImages[example.name]" class="example-preview" />
      </div>
    </code-layout>
  </SGWrapper>
</template>

<script>
import CodeLayout from '../CodeLayout'
import { examplesArray, exampleComponents } from './examples'
import SGWrapper from '@/components/Wrapper'
export default {
  name: 'SnackbarExample',
  components: {
    CodeLayout,
    SGWrapper,
    ...exampleComponents
  },
  data () {
    return {
      examplesArray,
      exampleImages: {
        SnackError: 'https://trello-attachments.s3.amazonaws.com/5b2e7a05f05c957a1cfc67c2/5ec506e63889cf5f2ba2886d/540abd113c93b6adbc5be6908319b3ce/Error.png',
        Success: 'https://trello-attachments.s3.amazonaws.com/5b2e7a05f05c957a1cfc67c2/5ec506e63889cf5f2ba2886d/b4c7c6fe2455716888ae591d4a1d7b45/Success.png',
        Warning: 'https://trello-attachments.s3.amazonaws.com/5b2e7a05f05c957a1cfc67c2/5ec506e63889cf5f2ba2886d/23bf060a3ba0160493ffc89f986b043b/Warning.png'
      }
    }
  }
}
</script>
<style lang="sass">
img.example-preview
  max-width: 100%
</style>
