<template>
  <div class="d-flex justify-space-around">
    <ct-checkbox
      label="Checkbox"
      disabled input-value="true"
    ></ct-checkbox>
    <ct-checkbox
      label="Checkbox"
      disabled
    ></ct-checkbox>
  </div>
</template>

<script>
import CtCheckbox from 'clevertap-dashboard-components/components/Checkbox/CtCheckbox'

export default {
  components: {
    CtCheckbox
  }
}
</script>
