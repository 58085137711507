<template>
  <div class="top-tabs px-6">
    <ct-tabs>
      <ct-tab v-for="tab in tabs" :key="tab.id" :to="tab.id">{{ tab.label }}</ct-tab>
    </ct-tabs>
  </div>
</template>
<script>
import { CtTabs, CtTab } from 'clevertap-dashboard-components/components/Tabs'

export default {
  components: {
    CtTabs,
    CtTab
  },

  props: {
    hideTabs: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },

  data () {
    return {
      tabItems: [
        { id: 'examples', label: 'Examples' },
        { id: 'guidelines', label: 'Guidelines' },
        { id: 'api', label: 'API' }
      ]
    }
  },

  computed: {
    tabs () {
      return this.tabItems.filter(tab => !this.hideTabs.includes(tab.id))
    }
  }
}
</script>
<style scoped>
.top-tabs {
  background-color: #F9F9FB;
}
.top-tabs >>> .v-tabs-bar {
  background-color: transparent !important;
}
</style>
