import client, { setCacheTs } from './client'

const QUERY_CACHE = {}

function normaliseQueryKey (queryUrl) {
  const queryParams = new URLSearchParams(queryUrl.split('?')[1])
  queryParams.sort()
  return queryParams.toString()
}

function addToCache (key, value, validFor, cacheTs) {
  key = normaliseQueryKey(key)
  const obj = {
    value,
    validity: Date.now() + validFor,
    cacheTs: cacheTs
  }
  QUERY_CACHE[key] = obj
}

function getFromCache (key) {
  key = normaliseQueryKey(key)
  const obj = QUERY_CACHE[key]
  const currentTs = Date.now()
  if (obj && obj.validity > currentTs) {
    if (obj.cacheTs) {
      setCacheTs(obj.cacheTs)
    }
    return obj.value
  }
  return null
}

function setAddToCache (result, queryUrl, validFor) {
  let data = result.data
  if (result.cacheTs) data.cacheTs = result.cacheTs
  let cacheTs = result.cacheTs || 0
  // save to cache even if useCache is false.
  addToCache(queryUrl, data, validFor, cacheTs)
  return data
}

export default {
  get (url, params = {}, options = {}) {
    return new Promise((resolve, reject) => {
      client.get(url, params, options).then(response => {
        if (response && response.status === 200) {
          resolve(response.data)
        } else {
          reject(response)
        }
      })
    })
  },
  post (url, data, headers, options = {}) {
    return new Promise((resolve, reject) => {
      client.post(url, data, headers, options).then(response => {
        if (response && response.status === 200) {
          resolve(response.data)
        } else {
          reject(response)
        }
      })
    })
  },
  getQueryResult (queryUrl, useCache = true, validFor = 10000) {
    return new Promise((resolve, reject) => {
      if (useCache) {
        const cachedValue = getFromCache(queryUrl)
        if (cachedValue) {
          resolve(cachedValue)
          return
        }
      }
      client.getQueryResult(queryUrl, useCache).then(response => {
        if (response && response.status === 200) {
          let data = setAddToCache(response, queryUrl, validFor)
          resolve(data)
        } else {
          reject(response)
        }
      })
    })
  },
  postQueryResult (queryUrl, useCache = true, validFor = 10000, data = {}, headers = {}) {
    return new Promise((resolve, reject) => {
      if (useCache) {
        const cachedValue = getFromCache(queryUrl)
        if (cachedValue) {
          resolve(cachedValue)
          return
        }
      }
      client.postQueryResult(queryUrl, data, headers, useCache).then(response => {
        if (response && response.status === 200) {
          let data = setAddToCache(response, queryUrl, validFor)
          resolve(data)
        } else {
          reject(response)
        }
      })
    })
  }
}
