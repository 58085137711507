<template>
  <ct-unified-select
    placeholder="Some Placeholder Text"
    :items="[1,2,3]"
  ></ct-unified-select>
</template>

<script>
import CtUnifiedSelect from 'clevertap-dashboard-components/components/Select/CtUnifiedSelect'

export default {
  components: {
    CtUnifiedSelect
  }
}
</script>
