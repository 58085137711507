<template>
  <div>
    <top-tabs :hideTabs="['api']"/>
    <router-view />
  </div>
</template>
<script>
import TopTabs from '../TopTabs'
import { mapMutations } from 'vuex'
const PageProperties = {
  componentName: 'Typography',
  componentTag: null,
  sourceURL: 'https://bitbucket.clevertap.net/projects/DAS/repos/dashboard-components/browse'
}
export default {
  name: 'Typography',
  components: {
    TopTabs
  },
  methods: {
    ...mapMutations('components', { setProperty: 'SET_PROPERTY' })
  },
  mounted () {
    this.setProperty(PageProperties)
  }
}
</script>
<style scoped>

</style>
