<template>
    <div>
        <ct-row>
            <ct-col class="title d-flex align-center">
                <ct-time-picker v-model="time" placeholder="Pick Time">
                    <template v-slot:hint>
                        Hint Text
                    </template>
                </ct-time-picker>
            </ct-col>
        </ct-row>
    </div>
</template>

<script>
import { CtRow, CtCol } from '@/../../components/Grid/GridSystem'
import CtTimePicker from '@/../../components/TimePicker/CtTimePicker'

export default {
  components: {
    CtRow,
    CtCol,
    CtTimePicker
  },
  data () {
    return {
      time: null
    }
  }
}
</script>
