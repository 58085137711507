import Vue from 'vue'
const EventBus = new Vue()
const Events = {
  REFRESH_DATA: 'refreshData',
  HANDLE_MEDIA_CONTROLS: 'handle-media-controls',
  CLOSE_TV_MODE: 'closeTvMode',
  TV_MODE: 'tvMode',
  TOGGLE_TV_MODE: 'toggleTVMode',
  DEMO_OP_DENIED: 'demoOpDenied'
}

export { EventBus, Events }
