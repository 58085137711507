<template>
  <div>
    <div>
      <h2>Primary color palette</h2>
      <p>
        Our primary palette includes three colors - Blue, Blue-grey (Neutral) and white.
      </p>
    </div>
    <div class="d-flex justify-space-around">
      <color-tile :rgb="'67, 71, 97'" :hex="'#434761'" :primary="true" :title="'Blue-grey'" :dark="true"></color-tile>
      <color-tile :rgb="'18, 107, 255'" :hex="'#126BFF'" :primary="true" :title="'Primary Blue'" :dark="true"></color-tile>
      <color-tile :rgb="'255, 255, 255'" :hex="'#FFFFFF'" :primary="true" :title="'White'"></color-tile>
    </div>
    <p>
      We use Blue for primary actions, buttons, links. Blue-grey is used primarily for body text and headings, and white is used for page and card backgrounds and other elements that need to be white.
    </p>
  </div>
</template>

<script>
import ColorTile from '../ColorTile.vue'
export default {
  components: {
    ColorTile
  }
}
</script>

<style scoped lang="scss">
  div {
    h2 {
      margin-top: 2.2rem;
      margin-left: 1.6rem;
    }
    p {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
      margin-left: 1.6rem;
    }
  }
</style>
