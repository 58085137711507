<template>
  <div class="guidelines pa-6 t-16">
    <vue-markdown class="t-14" v-if="guidelinesMd">{{ guidelinesMd }}</vue-markdown>
  </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import { getGuidelines } from '@/utils/guidelines'
export default {
  components: {
    VueMarkdown
  },
  data () {
    return {
      guidelinesMd: null
    }
  },
  created () {
    getGuidelines(this.$route.path).then(r => { this.guidelinesMd = r })
  }
}
</script>
<style scoped>
>>> h3 {
  font-size: 16px !important;
  margin-bottom: 11px;
  color: #434761;
}

>>> hr {
  margin: 24px 0;
  background: #F1F2F5;
  height: 2px;
  border: 0;
}

>>> p img {
  display: block;
  margin: auto;
}

>>> table {
  text-align: left;
  width: 100%;
}

>>> th, >>> td {
  padding: 8px;
}
</style>
