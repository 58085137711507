import Error from './SnackError'
import Warning from './Warning'
import Success from './Success'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../examplesImporter'

const exampleComponents = {
  Error,
  Success,
  Warning
}

export { examplesArray, exampleComponents }
