<template>
  <div>
    <div>
      <h2>Interaction color palette</h2>
      <p>
        These colors denote component interactions. They are widely used throughout the CleverTap form field and icons interaction.
      </p>
    </div>
    <div class="d-flex justify-space-around">
      <color-tile :rgb="'231, 234, 255'" :hex="'#E7EAFF'" :title="'Information Purple 2'"></color-tile>
      <color-tile :rgb="'255, 245, 213'" :hex="'#FFF5D5'" :title="'Warning Yellow 2'"></color-tile>
      <color-tile :rgb="'226, 240, 178'" :hex="'#E2F0B2'" :long="true" :title="'Developer Green (Internal Use Only)'"></color-tile>
    </div>
    <p>
    </p>
  </div>
</template>

<script>
import ColorTile from '../ColorTile.vue'
export default {
  components: {
    ColorTile
  }
}
</script>

<style scoped lang="scss">
  div {
    h2 {
      margin-top: 2.2rem;
      margin-left: 1.6rem;
    }
    p {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
      margin-left: 1.6rem;
    }
  }
</style>
