import NarrowCard from './NarrowCard'
import SquareCard from './SquareCard'
import WideCard from './WideCard'
import ExpandedCard from './ExpandedCard'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../examplesImporter'

const exampleComponents = {
  NarrowCard,
  SquareCard,
  WideCard,
  ExpandedCard
}

export { examplesArray, exampleComponents }
