var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ct-row',[_c('ct-col',[_c('ct-table',{attrs:{"inline-style":{ height: 'auto', maxHeight: '400px' },"scrollable":"scrollable","columns":_vm.cols,"data-items":_vm.tableItems,"sortable":_vm.defaultTable.sortable,"sort":_vm.defaultTable.sort,"selectAll":_vm.defaultTable.selectAll,"loading":_vm.defaultTable.loading,"navigation-bar":_vm.defaultTable.navBar,"search-bar":_vm.defaultTable.searchBar,"tool-bar":_vm.defaultTable.toolBar,"selected-field":'selected',"col-selector":_vm.defaultTable.colSelector,"is-percentage-width":true},on:{"update:columns":function($event){_vm.cols=$event},"selection":_vm.handleRowSelection,"selection-all":_vm.handleSelectAll,"searchText":_vm.handleSearch,"addAction":_vm.handleAddAction,"pagechange":_vm.handlePageChange,"sortchange":_vm.handleSort},scopedSlots:_vm._u([{key:"searchbar",fn:function(){return [_c('ct-textfield',{staticClass:"search-input",attrs:{"placeholder":_vm.placeholder,"hide-details":""},on:{"input":_vm.handleSearch},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}},[_c('ct-icon',{staticClass:"mt-1",attrs:{"slot":"prepend-inner","size":"16"},slot:"prepend-inner"},[_vm._v(" cticon-search ")])],1),_c('ct-filter',{staticClass:"filters",attrs:{"filter-heading":"Set Filters"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ct-button',_vm._g({attrs:{"color":"secondaryBtn","icon":""}},on),[_c('v-badge',{attrs:{"color":"primary","content":_vm.filterCount,"transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(_vm._s(_vm.filtercount))]},proxy:true}],null,true)},[_c('ct-icon',[_vm._v("cticon-filter")])],1)],1)]}}])},[_c('div',[_c('ct-combobox',{attrs:{"items":_vm.items,"chips":"","clearable":"","label":"Label","multiple":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}]),model:{value:(_vm.chips),callback:function ($$v) {_vm.chips=$$v},expression:"chips"}}),_c('ct-unified-select',{attrs:{"placeholder":"Some Placeholder Text","label":"Label","items":[1,2,3]},model:{value:(_vm.input1),callback:function ($$v) {_vm.input1=$$v},expression:"input1"}}),_c('ct-unified-select',{attrs:{"placeholder":"Some Placeholder Text","label":"Label","items":['','Sent','Reply','Error']},model:{value:(_vm.input2),callback:function ($$v) {_vm.input2=$$v},expression:"input2"}})],1)]),_c('ct-button',{staticClass:"Addbtn",attrs:{"color":"secondaryBtn"},on:{"click":_vm.handleAddAction}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.actionButtonHTML)}})])]},proxy:true},{key:"toolbar",fn:function(){return [_vm._v(" Showing "+_vm._s(_vm.rows)+" rows ")]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }