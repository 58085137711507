<template>
  <v-card
    outlined
    tile
    class="card"
    :style="style"
    v-bind:class="{ 'dark': isDarkBackground }"
  >
    <div class="card-title">
      <span
        v-bind:class="{ 'primary': isPrimary }"
      >
        {{ title }}
      </span>
    </div>
    <div
      v-bind:class="{ 'long': isLong }"
      class="card-palette"
    >
      <div>
        Hex <span>{{ hex }}</span>
        <div class="spacer"></div>
        RGB <span>{{ rgb }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import { VCard } from 'vuetify/lib'

let _VCard = VCard

export default {
  name: 'ColorTile',
  extends: _VCard,
  props: {
    title: String,
    hex: String,
    rgb: String,
    dark: Boolean,
    long: Boolean,
    primary: Boolean
  },
  computed: {
    isDarkBackground () {
      return this.dark ? this.dark : false
    },
    isLong () {
      return this.long ? this.long : false
    },
    isPrimary () {
      return this.primary ? this.primary : false
    },
    style () {
      return 'background-color: ' + this.hex
    }
  }
}
</script>

<style scoped lang="scss">
  .dark {
    color: #ffffff !important;
  }
  .card {
    color: #434761;
    min-width: 261px;
    max-width: 261px;
    height: 152px;
    padding-top: 16px;
    padding-left: 14px;
    border-radius: 4px;

    .long {
      margin-top: 16px !important;
    }
    .primary {
      font-size: 24px !important;
      background-color: transparent !important;
    }

    .card-title {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.01px;
      line-height: 20px;
    }
    .card-palette {
      margin-top: 43px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.01px;
      line-height: 20px;

      .spacer {
        margin-top: 8px;
      }

      span {
        margin-left: 22px;
      }
    }
  }
</style>
