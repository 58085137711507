import routes from './routes'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history' // TODO revert this if aws changes are not done
})

export default router
