<template>
    <div>
        <ct-row>
            <ct-col>
                <ct-time-picker v-model="time" placeholder="Pick time"></ct-time-picker>
            </ct-col>
    </ct-row>
    </div>
</template>

<script>
import { CtRow, CtCol } from '@/../../components/Grid/GridSystem'
import CtTimePicker from '@/../../components/TimePicker/CtTimePicker'

export default {
  components: {
    CtRow,
    CtCol,
    CtTimePicker
  },
  data () {
    return {
      time: null
    }
  }
}
</script>
