<template>
  <ct-app>
    <Navigation/>
    <v-content>
      <div class="content-wrapper pa-8">
        <page></page>
      </div>
    </v-content>
    <app-nagbar></app-nagbar>
  </ct-app>
</template>

<script>
import CtApp from 'clevertap-dashboard-components/components/App/CtApp'
import Navigation from '@/components/Navigation'
import Page from '@/components/Page'
import { mapMutations } from 'vuex'
import { NagbarItem, NagBarTypes } from 'clevertap-dashboard-components/components/Nagbar'
import AppNagbar from 'clevertap-dashboard-components/components/App/AppNagbar'
export default {
  name: 'App',

  components: {
    CtApp,
    AppNagbar,
    Navigation,
    Page
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
    ...mapMutations('site', { queueNagbar: 'QUEUE_NAGBAR' })
  },

  created () {
    this.queueNagbar(new NagbarItem({
      content: 'Looking for the old component previews? <a href="https://components.dashboard.clevertap.tech/v1/#/typography">Click here</a>',
      type: NagBarTypes.WARNING
    }))
  },

  mounted () {
  }
}
</script>
<style lang="scss" scoped>
</style>
