<template>
  <ct-unified-select
    placeholder="Some Placeholder Text"
    :items="items"
    v-model="val"
    :rules="rules"
    error
    multiple
  ></ct-unified-select>
</template>

<script>
import CtUnifiedSelect from 'clevertap-dashboard-components/components/Select/CtUnifiedSelect'

export default {
  components: {
    CtUnifiedSelect
  },
  data: () => ({
    rules: [
      (value) => 'Error'
    ],
    val: [],
    items: [
      {
        text: 'Item 1',
        value: '1'
      }, {
        text: 'Item 2',
        value: '2'
      }, {
        text: 'Item 3',
        value: '3'
      }
    ]
  })
}
</script>
