<template>
  <SGWrapper>
    <div class="title">Usage examples</div>
    <div class="filter-section d-flex">
      <div>Filter the type with appropriate size, weight and color to get the result</div>
      <div class="filter-switch">
        <ct-filter filter-heading="Set Typography" @apply="buildStyle">
          <template #activator="{on}">
            <ct-button color="secondaryBtn" icon v-on="on">
              <v-badge
                color="primary"
                :content="filterCount"
                transition="slide-x-transition"
              >
                <template v-slot:badge>{{filterCount}}</template>
                <ct-icon>cticon-filter</ct-icon>
              </v-badge>
            </ct-button>
          </template>
          <div>
            <ct-unified-select
              placeholder=""
              label="Font Size"
              :items="availableFontSize"
              v-model="fontSize"
            ></ct-unified-select>
            <ct-unified-select
              class="mt-6"
              placeholder="Some Placeholder Text"
              label=""
              :items="getFontWeights"
              v-model="fontWeight"
            ></ct-unified-select>
            <ct-unified-select
              class="mt-6"
              placeholder=""
              label="Color"
              :items="availableColors"
              v-model="fontColor"
            ></ct-unified-select>
          </div>
        </ct-filter>
      </div>
      <ct-button class="clear-all" @click="clearFilter" color="negativeBtn">Clear filter</ct-button>
    </div>
    <div class="text-preview pa-4 mt-4 d-flex">
      <div class="text flex-grow-1 d-flex align-center" contenteditable="true" >
        {{ styleClass }}
      </div>
      <div class="copy d-flex align-center justify-center" @click="copytoClip">
        <ct-icon color="#126BFF">cticon-copy</ct-icon>
      </div>
    </div>
  </SGWrapper>
</template>
<script>
import SGWrapper from '@/components/Wrapper'
import CtButton from 'clevertap-dashboard-components/components/Button/CtButton'
import CtIcon from 'clevertap-dashboard-components/components/Icon/CtIcon'
import CtFilter from 'clevertap-dashboard-components/components/Filter/CtFilter'
import CtUnifiedSelect from 'clevertap-dashboard-components/components/Select/CtUnifiedSelect'
// import CtBadge from 'clevertap-dashboard-components/components/Badge/CtBadge'
export default {
  name: 'TypographyExample',
  components: {
    SGWrapper,
    CtButton,
    CtIcon,
    CtFilter,
    CtUnifiedSelect
  },
  data () {
    return {
      filterCount: 0,
      fontSize: 12,
      fontWeight: '',
      fontColor: '',
      styleClass: '',
      availableFontSize: [
        12,
        14,
        16,
        18,
        20,
        24,
        28
      ],
      availableFontWeights: [
        {
          text: 'Regular',
          value: 'font-weight-regular'
        },
        {
          text: 'Medium',
          value: 'font-weight-medium'
        },
        {
          text: 'Semibold',
          value: 'font-weight-semibold'
        },
        {
          text: 'Bold',
          value: 'font-weight-bold'
        }
      ],
      availableColors: [
        'blue-grey (default)'
      ]
    }
  },
  computed: {
    getFontWeights () {
      switch (this.fontSize) {
        case 12:
          return [
            {
              text: 'Regular',
              value: 'font-weight-regular'
            },
            {
              text: 'Medium',
              value: 'font-weight-medium'
            }
          ]
        case 14:
          return [
            {
              text: 'Regular',
              value: 'font-weight-regular'
            },
            {
              text: 'Medium',
              value: 'font-weight-medium'
            },
            {
              text: 'Semibold',
              value: 'font-weight-semibold'
            },
            {
              text: 'Bold',
              value: 'font-weight-bold'
            }
          ]
        case 16:
          return [
            {
              text: 'Medium',
              value: 'font-weight-medium'
            },
            {
              text: 'Semibold',
              value: 'font-weight-semibold'
            },
            {
              text: 'Bold',
              value: 'font-weight-bold'
            }
          ]
        case 18:
        case 20:
          return [
            {
              text: 'Semibold',
              value: 'font-weight-semibold'
            }
          ]
        case 24:
        case 28:
          return [
            {
              text: 'Semibold',
              value: 'font-weight-semibold'
            },
            {
              text: 'Bold',
              value: 'font-weight-bold'
            }
          ]
        default:
          return []
      }
    }
  },
  methods: {
    buildStyle () {
      this.filterCount = 1 + (this.fontWeight === '' ? 0 : 1) + (this.fontColor === '' ? 0 : 1)
      this.styleClass = `.t-${this.fontSize} .${this.fontWeight}`
    },
    copytoClip () {
      navigator.clipboard.writeText(this.styleClass)
    },
    clearFilter () {
      this.styleClass = ''
      this.fontSize = 12
      this.fontWeight = null
      this.filterCount = (this.fontWeight === '' ? 0 : 1) + (this.fontColor === '' ? 0 : 1)
    }
  }
}
</script>
<style lang="sass" scoped>
.filter-section
  > div
    display: inline-flex
    margin: 0 12px 0  0
    align-items: center
.text-preview
  border: 18px solid #F9F9FB
  border-radius: 4px
  .copy
    width: 36px
    height: 36px
    border: 1px solid #126BFF
    border-radius: 4px
    cursor: pointer
</style>
