<template>
  <div class="text-center">
    <ct-chip class="ma-2">
      Default
    </ct-chip>
  </div>
</template>

<script>
import CtChip from 'clevertap-dashboard-components/components/Chip/CtChip.vue'

export default {
  components: {
    CtChip
  }
}
</script>
