/**
 * Code common across CtSelect, CtAutocomplete, CtCombobox goes here.
 */
export default {
  props: {
    appendIcon: {
      type: String,
      default: 'cticon-caret'
    },
    persistentHint: {
      type: Boolean,
      default: true
    },
    singleLine: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    itemValue: {
      type: [String, Array, Function],
      default: 'value'
    },
    items: {
      type: Array,
      default: () => ([])
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String
    },
    errorMessages: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    counter: {
      type: [Boolean, String, Number]
    },
    itemDisabled: {
      type: [String, Array, Function]
    }
  },
  model: {
    event: 'input',
    prop: 'value'
  },
  data: () => ({
    selectedItems: []
  }),
  computed: {
    likesAllItems () {
      return this.selectedItems.length === this.items.length
    },
    likesSomeItems () {
      return this.selectedItems.length > 0 && !this.likesAllItems
    },
    icon () {
      if (this.likesAllItems) return 'cticon-Checkbox-selected'
      if (this.likesSomeItems) return 'cticon-Checkbox-multiple'
      return 'cticon-Checkbox-deselected'
    },
    _counter () {
      if (typeof this.counter === 'number') {
        return this.counter
      } else if (typeof this.counter === 'string') {
        let counter = parseInt(this.counter)
        if (!isNaN(counter)) {
          return counter
        }
      }
      return 0
    },
    hasCounter () {
      if (this._counter) {
        return true
      }
      return false
    },
    _itemDisabled () {
      if (this.hasCounter) {
        return this.disableItem
      }
      return this.itemDisabled
    }
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.likesAllItems) {
          this.updateValue([])
        } else {
          let values = []
          for (let item of this.items) {
            if (typeof item === 'object' && !this.returnObject) {
              values.push(item[this.itemValue])
            } else {
              values.push(item)
            }
          }
          this.updateValue(values)
        }
      })
    },
    updateValue (newValue) {
      this.selectedItems = newValue
      this.$emit('input', newValue)
      this.$emit('change', newValue)
    },
    isSelected (item) {
      if (this.returnObject || typeof item === 'string') {
        return this.selectedItems.includes(item)
      }
      if (typeof item === 'object') {
        return this.selectedItems.findIndex(i => i === item[this.itemValue]) > -1
      }
      return false
    },
    disableItem (item) {
      let selected = this.isSelected(item)
      if (selected) {
        return false
      }
      return this.selectedItems.length >= this._counter
    }
  }
}
