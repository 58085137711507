import defaultTextField from './defaultTextField'
import defaultTextFieldHint from './defaultTextFieldHint'
import textFieldLabel from './textFieldLabel'
import textFieldLabelHint from './textFieldLabelHint'
import disabledtextFieldLabelHint from './disabledtextFieldLabelHint'
import errorTextField from './errorTextField'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../examplesImporter'

const exampleComponents = {
  defaultTextField,
  defaultTextFieldHint,
  textFieldLabel,
  textFieldLabelHint,
  disabledtextFieldLabelHint,
  errorTextField
}

export { examplesArray, exampleComponents }
