import CtGrid from './_CtGrid'
import CtGridNoRecords from './_CtGridNoRecords'
export * from '@progress/kendo-data-query'

export default {
  CtGrid,
  CtGridNoRecords
}

export {
  CtGrid,
  CtGridNoRecords
}
