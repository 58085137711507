<script>
import { mapMutations } from 'vuex'
import { NagbarItem, NagBarTypes } from 'clevertap-dashboard-components/components/Nagbar'
export default {
  name: 'NagsInfo',
  methods: {
    ...mapMutations('site', { queueNagbar: 'QUEUE_NAGBAR' }),
    showNagbar () {
      this.queueNagbar(new NagbarItem({
        content: 'This user has opted out from sharing the data. The last <a>event received</a> was on Mon, Jun 18, 2018.',
        type: NagBarTypes.INFORMATION
      }))
    }
  }
}
</script>
