<template>
  <div>
    <top-tabs :hide-tabs="['api']"/>
    <router-view />
  </div>
</template>

<script>
import TopTabs from '../TopTabs'
import { mapMutations } from 'vuex'

const PageProperties = {
  componentName: 'Tabs',
  componentTag: 'ct-tabs, ct-tab'
}

export default {
  name: 'Tabs',
  components: {
    TopTabs
  },
  methods: {
    ...mapMutations('components', { setProperty: 'SET_PROPERTY' })
  },
  mounted () {
    this.setProperty(PageProperties)
  }
}
</script>
