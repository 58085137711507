<template>
  <SGWrapper>
    <div class="title">Usage examples</div>
    <code-layout v-for="(example, index) in examplesArray" :heading="example.name" :displayCode="example.content" :key="index">
      <div class="example text-center">
        <img :src="exampleImages[example.name]" class="example-preview" />
      </div>
    </code-layout>
  </SGWrapper>
</template>

<script>
import CodeLayout from '../CodeLayout'
import { examplesArray, exampleComponents } from './examples'
import SGWrapper from '@/components/Wrapper'
export default {
  name: 'NagsExample',
  components: {
    CodeLayout,
    SGWrapper,
    ...exampleComponents
  },
  data () {
    return {
      examplesArray,
      exampleImages: {
        Alert: 'https://trello-attachments.s3.amazonaws.com/5b2e7a05f05c957a1cfc67c2/5ec506e63889cf5f2ba2886d/b26114be8b35053336b1ae773073b760/nag-alert.png',
        Info: 'https://trello-attachments.s3.amazonaws.com/5ec506e63889cf5f2ba2886d/863x62/d1c1fa8b10460dcd4dc7f94c10e2be82/nag-info.png',
        Warning: 'https://trello-attachments.s3.amazonaws.com/5ec506e63889cf5f2ba2886d/863x63/d531dbd6ec29ac04773437e7ac8b7ae9/nag-warn.png'
      }
    }
  }
}
</script>
<style lang="sass">
img.example-preview
  max-width: 100%
</style>
