import SingleSelect from '../components/dropdown/single-select/SingleSelect'
import SingleSelectExample from '../components/dropdown/single-select/SingleSelectExample'

import MultiSelect from '../components/dropdown/multi-select/MultiSelect'
import MultiSelectExample from '../components/dropdown/multi-select/MultiSelectExample'

// Import Alert
import AlertGeneric from '../components/alerts/AlertGeneric'
import AlertGenericExample from '../components/alerts/AlertGenericExample'

// Import Checkbox
import Checkbox from '../components/selection-controls/checkbox/Checkbox'
import CheckboxExample from '../components/selection-controls/checkbox/CheckboxExample'
// Import Color
import Color from '../components/color/Color'
import ColorExample from '../components/color/ColorExample'
// Import GridSystem
import GridSystem from '../components/grid-system/GridSystem'
// Import Radio
import Radio from '../components/selection-controls/radio/Radio'
import RadioExample from '../components/selection-controls/radio/RadioExample'
// Import Switch
import SwitchDefault from '../components/selection-controls/switch/Switch'
import SwitchExample from '../components/selection-controls/switch/SwitchExample'
// Typography
import Typography from '../components/typography/Typography'
import TypographyExample from '../components/typography/TypographyExample'

import Guidelines from '@/components/Guidelines'
import API from '@/components/API'

import SimpleButton from '../components/buttons/simple-button/SimpleButton'
import ButtonExample from '../components/buttons/simple-button/ButtonExample'

// Import Button Group
import ButtonGroup from '../components/buttons/button-group/ButtonGroup'
import ButtonGroupExample from '../components/buttons/button-group/ButtonGroupExample'

// Import Tabs
import Tabs from '../components/tabs/Tabs'
import tabsExample from '../components/tabs/TabsExample'

// Import Tag
import Tag from '../components/tag/Tag'
import tagExample from '../components/tag/TagExample'

import Search from '../components/Search/Search'
import SearchExample from '../components/Search/SearchExample'

import Nags from '../components/nags/Nags'
import NagsExample from '@/components/nags/NagsExample'

import Snackbar from '../components/snackbar/Snackbar'
import SnackbarExample from '../components/snackbar/SnackbarExample'

import tooltip from '../components/tooltip/tooltip'
import tooltipExample from '../components/tooltip/tooltipExample'

import textField from '../components/textField/textField'
import textfieldExample from '../components/textField/textfieldExample'

import textArea from '../components/textArea/textArea'
import textAreaExample from '../components/textArea/textAreaExample'

import breadCrumb from '../components/breadcrumbs/Breadcrumbs'
import breadCrumbExample from '../components/breadcrumbs/BreadcrumbsExample'

import modal from '../components/modal/Modal'
import modalExample from '../components/modal/modalExample'

import Chips from '../components/chips/Chips'
import chipsExample from '../components/chips/chipsExample'

import Cards from '../components/Cards/card'
import cardExample from '../components/Cards/cardExample'

import TimePicker from '../components/TimePicker/TimePicker'
import timePickerExample from '../components/TimePicker/timePickerExample'

import Table from '../components/Tables/Table'
import TableExample from '../components/Tables/TableExample'

const routes = [
  {
    path: '/',
    redirect: '/dropdowns/single-select'
  },
  {
    path: '/dropdowns/single-select',
    component: SingleSelect,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: SingleSelectExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/dropdowns/multi-select',
    component: MultiSelect,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: MultiSelectExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/selection-controls/checkbox',
    component: Checkbox,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: CheckboxExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/selection-controls/radio',
    component: Radio,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: RadioExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/selection-controls/switch',
    component: SwitchDefault,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: SwitchExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/buttons/SimpleButton',
    component: SimpleButton,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: ButtonExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/buttons/ButtonGroup',
    component: ButtonGroup,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: ButtonGroupExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/typography',
    component: Typography,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: TypographyExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      }
    ]
  },
  {
    path: '/Search/Search',
    component: Search,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: SearchExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/nags',
    component: Nags,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        name: 'Nags',
        component: NagsExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/snackbar',
    component: Snackbar,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: SnackbarExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/alerts/AlertGeneric',
    component: AlertGeneric,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: AlertGenericExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/tooltip',
    component: tooltip,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: tooltipExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      }
    ]
  },
  {
    path: '/textField',
    component: textField,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: textfieldExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/textArea',
    component: textArea,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: textAreaExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/breadcrumbs',
    component: breadCrumb,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: breadCrumbExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/modal',
    component: modal,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: modalExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/colors',
    component: Color,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: ColorExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      }
    ]
  },
  {
    path: '/grid-system',
    component: GridSystem,
    children: [
      {
        path: '',
        redirect: 'guidelines'
      },
      {
        path: 'guidelines',
        component: Guidelines
      }
    ]
  },
  {
    path: '/chips',
    component: Chips,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: chipsExample
      },
      {
        path: 'api',
        component: API
      },
      {
        path: 'guidelines',
        component: Guidelines
      }
    ]
  },
  {
    path: '/grid-system',
    component: GridSystem,
    children: [
      {
        path: '',
        redirect: 'guidelines'
      },
      {
        path: 'guidelines',
        component: Guidelines
      }
    ]
  },
  {
    path: '/tabs',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: tabsExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/tags',
    component: Tag,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: tagExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/card',
    component: Cards,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: cardExample
      },
      {
        path: 'api',
        component: API
      },
      {
        path: 'guidelines',
        component: Guidelines
      }
    ]
  },
  {
    path: '/timePicker',
    component: TimePicker,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: timePickerExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '/tables',
    component: Table,
    children: [
      {
        path: '',
        redirect: 'examples'
      },
      {
        path: 'examples',
        component: TableExample
      },
      {
        path: 'guidelines',
        component: Guidelines
      },
      {
        path: 'api',
        component: API
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

export default routes
