<template>
  <div class="d-flex justify-space-around">
    <div class="text-center">
      <v-btn-toggle>
        <ct-button class="secondary">
          Group 1
        </ct-button>
        <ct-button class="secondary">
          Group 2
        </ct-button>
      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
import CtButton from 'clevertap-dashboard-components/components/Button/CtButton'
export default {
  components: {
    CtButton
  }
}
</script>
