import Alert from './Alert'
import Warning from './Warning'
import Info from './Info'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../examplesImporter'

const exampleComponents = {
  Alert,
  Warning,
  Info
}

export { examplesArray, exampleComponents }
