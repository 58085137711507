<template>
  <!-- Looking to validate your input and display an error? Look into rules prop -->
  <ct-unified-select
    placeholder="Some Placeholder Text"
    :items="[1,2,3]"
    :rules="rules"
    error
  ></ct-unified-select>
</template>

<script>
import CtUnifiedSelect from 'clevertap-dashboard-components/components/Select/CtUnifiedSelect'

export default {
  components: {
    CtUnifiedSelect
  },
  data: () => ({
    rules: [
      (value) => 'Error'
    ]
  })
}
</script>
