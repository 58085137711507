<template>
  <SGWrapper>
    <div class="title">Usage examples</div>
    <code-layout v-for="(example, index) in examplesArray" :heading="example.name" :displayCode="example.content" :key="index">
      <component :is="example.name"/>
    </code-layout>
  </SGWrapper>
</template>

<script>
import CodeLayout from '../CodeLayout'
import { examplesArray, exampleComponents } from './examples'
import SGWrapper from '@/components/Wrapper'
export default {
  name: 'BreadcrumbsExample',
  components: {
    CodeLayout,
    SGWrapper,
    ...exampleComponents
  },
  data () {
    return {
      examplesArray
    }
  }
}
</script>

<style scoped>

</style>
