<template>
  <div class="d-flex justify-space-around">
    <ct-textarea placeholder="Placeholder" label="Label" hint="Hint" counter="125"></ct-textarea>
  </div>
</template>

<script>
import CtTextarea from 'clevertap-dashboard-components/components/Textarea/CtTextarea'
export default {
  components: {
    CtTextarea
  }
}
</script>
