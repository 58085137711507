import api from './api'
import { USERNAME, USER_NAME, USER_AVATAR, EFFECTIVE_ROLE, HOME_BOARD, NEW_CAMPAIGN, NEW_JOURNEY } from '../../constants'
import Vue from 'vue'

export const minimalUserState = Vue.observable({
  planFeatureInfo: {},
  planAndEventCountInfo: {},
  rbacDetails: {},
  featureFlags: {},
  isNewCampaign: NEW_CAMPAIGN,
  isNewJourney: NEW_JOURNEY
})

const generateUserInitials = (userName) => {
  if (!userName) {
    return ''
  }
  return userName
    .replace(/[^A-Za-z0-9À-ÿ ]/ig, '')
    .replace(/ +/ig, ' ')
    .split(/ /)
    .reduce((acc, item) => acc + item[0], '')
    .concat(name.substr(1))
    .concat(name)
    .substr(0, 2)
    .toUpperCase()
}

const slice2 = (num) => {
  return ('0' + num).slice(-2)
}

let tempAccessIntervalId = null

const defaultState = {
  email: USER_NAME,
  userName: USERNAME,
  userInitials: generateUserInitials(USERNAME),
  lastRoute: null,
  accounts: [],
  productUpdates: [],
  demoAccounts: [],
  loading: false,
  orgName: 'Reliance Ltd.', // TODO
  userAvatar: USER_AVATAR, // TODO
  planFeatureInfo: {},
  planAndEventCountInfo: {},
  rbacDetails: {},
  featureFlags: {},
  effectiveRole: EFFECTIVE_ROLE,
  tempAccessExpiry: -1,
  tempAccessRemainingString: null,
  homeBoardId: HOME_BOARD,
  isNewCampaign: NEW_CAMPAIGN,
  isNewJourney: NEW_JOURNEY,
  startTimestamp: null
}

const user = {
  namespaced: true,
  state: Object.assign({}, defaultState),
  getters: {
    planFeatureInfo (state) {
      return state.planFeatureInfo
    },
    rbacDetails (state) {
      return state.rbacDetails
    },
    featureFlags (state) {
      return state.featureFlags
    },
    planAndEventCountInfo (state) {
      return state.planAndEventCountInfo
    }
  },
  mutations: {
    SET_ACCOUNTS (state, accounts) {
      state.accounts = accounts.accounts
      state.demoAccounts = accounts.demo
      state.tempAccessExpiry = accounts.tempAccessExpiry
    },
    SET_LOADING (state, value) {
      state.loading = value
    },
    SET_TEMP_ACCESS_TIME_REMAINING (state) {
      if (state.tempAccessExpiry < 0) {
        return
      }
      const now = Date.now() / 1000
      const left = state.tempAccessExpiry - now
      const remainingString = left > 0 ? `Exp ${slice2(parseInt(left / 60))}:${slice2(parseInt(left % 60))}` : ''
      if (left >= 0) {
        state.tempAccessRemainingString = remainingString
        document.dispatchEvent(new CustomEvent('temp-access-timer-update', { detail: remainingString }))
      } else {
        document.dispatchEvent(new CustomEvent('temp-access-timer-update', { detail: null }))
        state.tempAccessRemainingString = null
        clearTimeout(tempAccessIntervalId)
      }
    },
    SET_PLAN_FEATURE_INFO (state, payload) {
      if (payload) {
        const { addons, planDetails, nextMonthStartTime, tobeRemovedAddons = [], addedAddons = [] } = payload // Destructuring required properties from payload
        const planFeatureInfo = { addons, planDetails, nextMonthStartTime, tobeRemovedAddons, addedAddons }
        state.planFeatureInfo = planFeatureInfo
        minimalUserState.planFeatureInfo = planFeatureInfo
      }
    },
    SET_PLAN_AND_EVENT_COUNT (state, payload) {
      if (payload) {
        state.planAndEventCountInfo = { ...payload }
        minimalUserState.planAndEventCountInfo = { ...payload }
      }
    },
    SET_RBAC_DETAILS  (state, payload) {
      if (payload && payload.success) {
        const { readOnlyAccess, readAndWriteAccess, customRolePresent, isUserMaker, isUserApprover } = payload
        const rbacDetails = { readOnlyAccess, readAndWriteAccess, customRolePresent, isUserMaker, isUserApprover }
        state.rbacDetails = rbacDetails
        minimalUserState.rbacDetails = rbacDetails
      }
    },
    SET_FEATURE_FLAGS (state, payload) {
      state.featureFlags = payload
      minimalUserState.featureFlags = payload
      window.ctFeatureFlags = Object.assign({}, payload)
    },
    SET_HOME_BOARD_ID (state, payload) {
      state.homeBoardId = payload
    },
    SET_NEW_CAMPAIGN (state, payload) {
      minimalUserState.isNewCampaign = payload
      state.isNewCampaign = payload
    },
    SET_NEW_JOURNEY (state, payload) {
      minimalUserState.isNewJourney = payload
      state.isNewJourney = payload
    },
    SET_START_TIMESTAMP (state) {
      state.startTimestamp = Date.now()
    }
  },
  actions: {
    updateTempAccessTime ({ state, commit, dispatch }) {
      commit('SET_TEMP_ACCESS_TIME_REMAINING')
      if (state.tempAccessRemainingString !== null) {
        tempAccessIntervalId = setInterval(() => {
          commit('SET_TEMP_ACCESS_TIME_REMAINING')
        }, 1000)
      }
    },
    async GET_ACCOUNTS ({ commit, dispatch }) {
      commit('SET_ACCOUNTS', await api.getAccounts())
      dispatch('updateTempAccessTime')
    },
    async GET_PLAN_FEATURE_INFO ({ commit }) {
      commit('SET_PLAN_FEATURE_INFO', await api.getPlanFeatureInfo())
    },
    async GET_PLAN_AND_EVENT_COUNT ({ commit }) {
      commit('SET_PLAN_AND_EVENT_COUNT', await api.getPlanAndEventCount())
    },
    async GET_RBAC_DETAILS ({ commit }) {
      commit('SET_RBAC_DETAILS', await api.getRBACDetails())
    }
  }
}
export default user
