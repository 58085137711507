import { render, staticRenderFns } from "./CtSelect.vue?vue&type=template&id=2b4a1db8&scoped=true&"
import script from "./CtSelect.vue?vue&type=script&lang=js&"
export * from "./CtSelect.vue?vue&type=script&lang=js&"
import style0 from "./CtSelect.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./CtSelect.vue?vue&type=style&index=1&id=2b4a1db8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../docs/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b4a1db8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../docs/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VDivider,VIcon,VListItem,VListItemAction,VListItemContent,VListItemTitle,VSelect})
