<template>
  <ct-unified-radio v-model="radios" disabled row :items="items"></ct-unified-radio>
</template>

<script>
import CtUnifiedRadio from 'clevertap-dashboard-components/components/Radio/CtUnifiedRadio'

export default {
  name: 'RadioDisabled',
  data () {
    return {
      radios: 'radio-1',
      items: [
        { label: 'Radio 1', value: 'radio-1' },
        { label: 'Radio 2', value: 'radio-2' }
      ]
    }
  },
  components: {
    CtUnifiedRadio
  }
}
</script>
