import PrimaryDefaultButton from './PrimaryDefaultButton'
import PrimaryIconButton from './PrimaryIconButton'
import SecondaryDefaultButton from './SecondaryDefaultButton'
import SecondaryIconButton from './SecondaryIconButton'
import SecondaryOnlyIconButton from './SecondaryOnlyIconButton'
import NegativeDefaultButton from './NegativeDefaultButton'
import WarningDefaultButton from './WarningDefaultButton'
import DropdownButton from './DropdownButton'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../../examplesImporter'

const exampleComponents = {
  PrimaryDefaultButton,
  PrimaryIconButton,
  SecondaryDefaultButton,
  SecondaryIconButton,
  SecondaryOnlyIconButton,
  NegativeDefaultButton,
  WarningDefaultButton,
  DropdownButton
}

export { examplesArray, exampleComponents }
