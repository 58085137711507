<template>
  <div class="d-flex justify-space-around">
    <ct-textarea placeholder="Placeholder text..." hint="Hint"></ct-textarea>
  </div>
</template>

<script>
import CtTextarea from 'clevertap-dashboard-components/components/Textarea/CtTextarea'
export default {
  components: {
    CtTextarea
  }
}
</script>
