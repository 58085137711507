<template>
  <ct-tooltip bottom>
    <template #activator="{ on }">
      <div v-on="on" class="wrapText">{{text}}}</div>
    </template>
    <span>{{text}}</span>
  </ct-tooltip>
</template>

<script>
import CtTooltip from 'clevertap-dashboard-components/components/Tooltip/CtTooltip'
export default {
  name: 'WrapTextTooltip',
  components: {
    CtTooltip
  },
  data () {
    return {
      text: 'Tooltip for wrapped text is shown like this'
    }
  }
}
</script>

<style scoped>
  .wrapText {
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
