import ActiveTag from './ActiveTag'
import CompletedTag from './CompletedTag'
import DraftTag from './DraftTag'
import ScheduledTag from './ScheduledTag'
import StoppedTag from './StoppedTag'
import UnsavedTag from './UnsavedTag'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../examplesImporter'

const exampleComponents = {
  ActiveTag,
  CompletedTag,
  DraftTag,
  ScheduledTag,
  StoppedTag,
  UnsavedTag
}

export { examplesArray, exampleComponents }
