<template>
  <div class="d-flex justify-space-around">
    <ct-textbox placeholder="Search" is-search></ct-textbox>
  </div>
</template>

<script>
import CtTextbox from 'clevertap-dashboard-components/components/Textfield/CtTextfield'
export default {
  components: {
    CtTextbox
  }
}
</script>
