<template>
  <div>
    <div>
      <h2>Interaction color palette</h2>
      <p>
        These colors denote component interactions. They are widely used throughout the CleverTap form field and icons interaction.
      </p>
    </div>
    <div class="d-flex justify-space-around">
      <color-tile :rgb="'236, 237, 242'" :hex="'#ECEDF2'" :title="'Selected/ Hover Gray'"></color-tile>
      <color-tile :rgb="'226, 237, 254'" :hex="'#E2EDFE'" :title="'Selected Blue'"></color-tile>
    </div>
    <p>
    </p>
  </div>
</template>

<script>
import ColorTile from '../ColorTile.vue'
export default {
  components: {
    ColorTile
  }
}
</script>

<style scoped lang="scss">
  div {
    h2 {
      margin-top: 2.2rem;
      margin-left: 1.6rem;
    }
    p {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
      margin-left: 1.6rem;
    }
  }
</style>
