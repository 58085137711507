<template>
  <div class="wrapper px-6 py-4">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'SGWrapper',
  components: {
  }
}
</script>
<style scoped>
.guidelines {
  margin-right: 280px;
}

.guidelines >>> * {
  font-size: 14px;
}

.wrapper >>> .title {
  font-size: 16px !important;
  line-height: 24px;
  color: #434761;
  font-weight: bold;
  margin-bottom: 11px;
}

.wrapper >>> .center {
  text-align: center;
}

.wrapper >>> .separator {
  width: calc(100% + 48px);
  margin-left: -24px;
  background-color: #F1F2F5;
  height: 2px;
  margin-top: 24px;
  margin-bottom: 24px;
}
</style>
