<template>
    <div class="comp-design-section">
      <div class="design-logo">S</div>
      <div class="design-info">
        <div class="design-name">Storm</div>
        <div class="design-version">Version {{ componentVersion }}</div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'CompDesignSection',
  computed: {
    componentVersion () {
      return process.env.VUE_APP_COMPONENTS_VERSION
    }
  }
}
</script>

<style scoped>
  .comp-design-section {
    display: flex;
    align-items: center;
    padding: 12px;
  }
  .design-logo {
    height: 36px;
    width: 36px;
    background-color: #25C1BD;
    border-radius: 50%;
    color: #FAFAFA;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .design-info {
    margin-left: 12px;
  }
  .design-name {
    color: #434761;
    font-size: 14px;
    font-weight: bold;
  }
  .design-version {
    color: #434761;
    font-size: 12px;
    font-weight: 500;
  }
</style>
