<template>
  <div class>
    <ct-sheet class="top-bar d-flex align-center px-6 elevation-1 mb-8">
      <span class="component-name mr-3 t-16 font-weight-semibold">{{ componentName }}</span>
      <code class="component-tag mr-6 t-12" v-if="componentTag">{{ componentTag }}</code>
      <span class="source t-14 font-weight-medium">
        Source:
        <a :href="sourceURL">Bitbucket</a>
      </span>
    </ct-sheet>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import CtSheet from 'clevertap-dashboard-components/components/Sheet/CtSheet'
export default {
  components: {
    CtSheet
  },

  computed: {
    ...mapState('components', [
      'componentName',
      'componentTag',
      'sourceURL'
    ])
  }
}
</script>
<style lang="sass" scoped>
.top-bar
  height: 72px
</style>
