import SingleSelectDefault from './SingleSelectDefault'
import SingleSelectDefaultWithPersistentHint from './SingleSelectDefaultWithPersistentHint'
import SingleSelectDefaultWithLabel from './SingleSelectDefaultWithLabel'
import SingleSelectDefaultWithLabelAndPersistentHint from './SingleSelectDefaultWithLabelAndPersistentHint'
import SingleSelectDefaultWithSearch from './SingleSelectDefaultWithSearch'
import SingleSelectDefaultWithHelpText from './SingleSelectDefaultWithHelpText'
import ValidationError from './ValidationError'
import SingleSelectCategorized from './SingleSelectCategorized'
import IconSingleSelect from './IconSingleSelect'
import SingleSelectDisabled from './SingleSelectDisabled'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../../examplesImporter'

const exampleComponents = {
  SingleSelectDefault,
  SingleSelectDefaultWithPersistentHint,
  SingleSelectDefaultWithLabel,
  SingleSelectDefaultWithLabelAndPersistentHint,
  SingleSelectDefaultWithSearch,
  SingleSelectDefaultWithHelpText,
  ValidationError,
  SingleSelectCategorized,
  IconSingleSelect,
  SingleSelectDisabled
}

export { examplesArray, exampleComponents }
