<template>
  <div class="ct-filter" :class="{'has-nag': hasNagBar}">
    <slot name="activator" :on="{click: activatorClicked}"><ct-button @click="overlay = true">Use activator slot</ct-button></slot>
    <v-overlay :value="overlay" z-index="20" :dark="false">
      <div class="filter-content">
        <div class="d-flex justify-space-between align-center py-4 px-6 blue-grey lighten-9">
          <div class="t-16 font-weight-medium blue-grey--text">{{filterHeading}}</div>
          <v-icon class="t-16 blue-grey--text" @click="closeFilter">mdi-close</v-icon>
        </div>
        <div class="pa-6 filter-body">
          <slot></slot>
        </div>
        <div class="d-flex align-center blue-grey lighten-9 py-4 px-6">
          <ct-button @click="applyChange">Apply</ct-button>
          <ct-button color="negativeBtn" class="ml-2" @click="closeFilter">Cancel</ct-button>
          <slot name="footerAction" :closeFilter="closeFilter"></slot>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
    import CtButton from '../Button/CtButton'
    import { mapState } from 'vuex'

    export default {
      name: 'CtFilter',
      components: { CtButton },
      props: {
        filterHeading: {
          type: String,
          default: ''
        },
        ignoreNagBar: {
          type: Boolean,
          default: false
        }
      },
      data: () => ({
        overlay: false,
        allowClose: true
      }),
      computed: {
        ...mapState('site', ['nagbarQueue']),
        hasNagBar () {
          return !this.ignoreNagBar && this.nagbarQueue.length > 0
        }
      },
      methods: {
        applyChange () {
          this.allowClose = true
          this.$emit('apply', { stopPropagation: this.stopPropagation })
          if (this.allowClose) {
            this.closeFilter()
          }
        },
        activatorClicked () {
          this.overlay = true
        },
        stopPropagation () {
          this.allowClose = false
        },
        closeFilter () {
          this.$emit('close')
          this.overlay = false
        }
      }
    }
</script>

<style scoped>
  .ct-filter >>> .v-overlay__content {
    position: fixed;
    width: 312px;
    background-color: #fff;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .ct-filter.has-nag >>> .v-overlay__content {
    top: 48px;
  }
  .filter-body {
    height: calc(100vh - 124px);
    color: #434761;
    overflow: auto;
  }
  .has-nag .filter-body {
    height: calc(100vh - 172px);
  }
</style>
