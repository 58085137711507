<template>
  <div>
    <div>
    <h2>Buttons, Confirmations, Alerts and Nags color palette</h2>
      <p>
        <b>Blue</b> has 2 tints. Primary Blue to show primary actions and Disabled Blue to show disabled or selected action, icon, buttons. <br>
        <b>Red</b> is mainly used to show alert, error in dropdowns, buttons and negative actions in buttons. <br>
        <b>Green</b> is mainly used to show success in snackbars, nags, icons. <br>
        <b>Yellow</b> is used as a warning in snackbar, nags. <br>
        <b>Information Purple 1</b> is used to show information, help and support in snackbar, nags.
      </p>
    </div>
    <div class="d-flex justify-space-around">
      <color-tile :rgb="'18, 107, 255'" :hex="'#126BFF'" :title="'Primary Blue'" :dark="true"></color-tile>
      <color-tile :rgb="'112, 166, 255'" :hex="'#70A6FF'" :title="'Disabled Blue'" :dark="true"></color-tile>
      <color-tile :rgb="'255, 83, 83'" :hex="'#FF5353'" :title="'Warning Red'" :dark="true"></color-tile>
    </div>
    <div class="d-flex justify-space-around mt-5">
      <color-tile :rgb="'43, 191, 102'" :hex="'#2BBF66'" :title="'Success Green'" :dark="true"></color-tile>
      <color-tile :rgb="'255, 213, 79'" :hex="'#FFD54F'" :title="'Warning Yellow 1'"></color-tile>
      <color-tile :rgb="'81, 108, 245'" :hex="'#516CF5'" :title="'Information Purple 1'" :dark="true"></color-tile>
    </div>
    <p>
    </p>
  </div>
</template>

<script>
import ColorTile from '../ColorTile.vue'
export default {
  components: {
    ColorTile
  }
}
</script>

<style scoped lang="scss">
  div {
    h2 {
      margin-top: 1rem;
      margin-left: 1.6rem;
    }
    p {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
      margin-left: 1.6rem;
    }
  }
</style>
