<template>
    <div>
        <ct-card width="976px" height="472px" max-width="1000px">
        </ct-card>
    </div>
</template>

<script>
import CtCard from 'clevertap-dashboard-components/components/Card/CtCard.vue'

export default {
  components: {
    CtCard
  }
}
</script>
