<template>
    <div>
      <top-tabs :hideTabs="['api']" />
      <router-view />
    </div>
</template>

<script>
import TopTabs from '../TopTabs'
import { mapMutations } from 'vuex'

const PageProperties = {
  componentName: 'Snackbar',
  componentTag: null
}

export default {
  name: 'Snackbar',
  components: {
    TopTabs
  },
  methods: {
    ...mapMutations('components', { setProperty: 'SET_PROPERTY' })
  },
  mounted () {
    this.setProperty(PageProperties)
  }
}
</script>
