<template>
  <div class="ct-textbox" :class="{compact: this.compact, compactWidth: this.compactWidth}" :style="dynamicStyle">
    <div class="text-estimator">
      <div class="inner" v-text="boundValue" ref="textEstimator"></div>
    </div>
    <div class="label mb-1 t-12 font-weight-medium" v-if="label">{{ label }}</div>
    <slot name="labelSlot" v-else-if="!!this.$slots.labelSlot"></slot>
    <v-text-field
      ref="field"
      @input="update"
      :value="value"
      :placeholder="placeholder"
      :persistentHint="persistentHint"
      :singleLine="singleLine"
      :items="items"
      :hint="hint"
      :disabled="disabled"
      :counter="counter"
      :rules="defaultRules"
      v-on="$listeners"
      v-bind="{...$attrs, ...compactProperties}"
    >
      <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template v-slot:prepend-inner v-if="isSearch"><ct-icon class="mt-1"  size="16">cticon-search</ct-icon></template>
    </v-text-field>
  </div>
</template>
<script>
import CtIcon from '../Icon/CtIcon'
export default {
  name: 'CtTextfield',
  components: { CtIcon },
  props: {
    persistentHint: {
      type: Boolean,
      default: true
    },
    isSearch: {
      // The text field is used as a searchbar (adds search icon)
      type: Boolean,
      default: false
    },
    singleLine: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => ([])
    },
    hint: {
      type: String,
      default: ''
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    counter: {
      type: [Boolean, String],
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    compact: {
      type: Boolean,
      default: false
    },
    compactWidth: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    prefillTracker: 0
  }),
  computed: {
    defaultRules () {
      if (this.counter) {
        return [
          ...this.rules,
          value => value.length <= this.counter || `Max ${this.counter} characters allowed`
        ]
      }
      return this.rules
    },
    compactProperties () {
      if (this.compact) {
        return ({
          filled: false,
          solo: true,
          flat: true,
          height: '24px',
          backgroundColor: '#eef4ff',
          hideDetails: true,
          dense: true,
          fullWidth: false,
          size: 12
        })
      } else {
        return ({
          filled: true,
          dense: this.dense
        })
      }
    },
    dynamicStyle () {
      if (this.compact) {
        if (this.width) {
          // ignore dynamic width since width was specified
          return ({
            width: `${this.width}px`,
            maxWidth: `${this.width}px`
          })
        }
        let unit = (this.value != null) && ((this.value.length) / (this.value.length)) + this.prefillTracker
        let width = this.$refs && this.$refs.textEstimator && this.$refs.textEstimator.clientWidth
        width = width ? Number(width) + unit : 0
        if (width > 30) {
          width = width + 40 + 'px'
        } else {
          width = '60px'
        }
        return ({
          width,
          maxWidth: '200px'
        })
      } else {
        return {}
      }
    },
    boundValue () {
      return this.value
    }
  },
  methods: {
    update (newValue) { this.$emit('input', newValue) },
    blur () {
      if (this.$refs.field) {
        this.$refs.field.blur()
      }
    },
    focus () {
      if (this.$refs.field) {
        this.$refs.field.focus()
      }
    },
    validate () {
      return (this.$refs.field) ? this.$refs.field.validate(true) : true
    }
  },
  mounted () {
    if (this.value && this.value.length > 0) {
      // prefilled
      this.prefillTracker++
    }
  }
}
</script>
<style lang="scss">
.ct-textbox {
  .v-input {

    &__slot {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      overflow: hidden;
    }

    &--is-disabled {
      .v-input__slot{
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        overflow: visible;
      }
    }

    &--is-focused, &--is-disabled, &.error--text {
      .v-input__slot {
        &::before {
          bottom: 0;
          border-top-width: 2px;
          border-radius: 0 0 4px 4px;
        }

        &::after {
          bottom: 0;
        }
      }
    }
  }
}
</style>
<style lang="sass" scoped>
.ct-textbox
  &.compact
    ::v-deep .v-input__slot,
    ::v-deep .v-input__control,
    ::v-deep .v-text-field.v-text-field--solo.v-input--dense>.v-input__control
      min-height: 24px
      font-size: 12px
  &.compactWidth
    .v-text-field.v-text-field--solo.v-input--dense
    min-width: 220px
.text-estimator
  position: absolute
  visibility: hidden
  height: 0
  width: 600px
  overflow: hidden
  .inner
    width: fit-content
</style>
