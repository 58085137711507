<template>
  <ct-tooltip bottom>
    <template #activator="{ on }">
      <ct-button color="primary" dark v-on="on">Save</ct-button>
    </template>
    <span>This is a button tooltip</span>
  </ct-tooltip>
</template>

<script>
import CtTooltip from 'clevertap-dashboard-components/components/Tooltip/CtTooltip'
import CtButton from 'clevertap-dashboard-components/components/Button/CtButton'
export default {
  name: 'ButtonTooltip',
  components: {
    CtTooltip,
    CtButton
  }
}
</script>

<style scoped>

</style>
