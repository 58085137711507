<template>
  <div>
    <ct-button class="mr-2" @click="showModal1">Open Small Modal</ct-button>
    <ct-modal modaltype="small" v-model="dialog" :persistent="false" title="Campaign Name">
      <template v-slot:modal-body>
        Modal Message
      </template>
      <template v-slot:modal-footer>
        <ct-button color="primary" @click="dialog = false">Continue</ct-button>
        <ct-button color="negativeBtn" @click="dialog = false">Cancel</ct-button>
      </template>
    </ct-modal>
  </div>
</template>

<script>
import CtModal from '@/../../components/Modal/CtModal'
import CtButton from '@/../../components/Button/CtButton'
export default {
  name: 'Modal',
  components: {
    CtModal,
    CtButton
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    showModal1 () {
      this.dialog = true
    }
  }
}
</script>

<style scoped>

</style>
