const __ACCOUNT_ID = window.__ACCOUNT_ID || null
const __USER_NAME = window.__USER_NAME || null
const __USERNAME = window.__USERNAME || null
const __ACCOUNT_NAME = window.__ACCOUNT_NAME || null
const __ACCOUNT_ICON = window.__ACCOUNT_ICON || null
const __USER_AVATAR = window.__USER_AVATAR || null
const __DATE_TIME = window.__DATE_TIME || null
const __IS_TEST_ACCOUNT = window.__IS_TEST_ACCOUNT || false
const __USER_IS_INTERNAL = window.__USER_IS_INTERNAL || false
const __ACCOUNT_INFO = window.ACCOUNT_INFO || {}
const __EFFECTIVE_ROLE = window.__EFFECTIVE_ROLE || 'UNKNOWN_ROLE'
const __IS_GHOST = window.__IS_GHOST || false // need to do a planned depreciation
const __ACCOUNT_IS_INTEGRATED = window.__ACCOUNT_IS_INTEGRATED || window.isDataComing || false
const __ACCOUNT_REGION = window.DATA_CENTER || 'eu'
const __maxDRP = parseInt(window.__maxDRP) || 364
const __SAMPLING_MODE = parseInt(window.SAMPLING_MODE)
const __SEGMENT_ROLE_INFO = window.__SEGMENT_ROLE_INFO
const __HOME_BOARD = window.__HOME_BOARD || -1
const __IS_ACCOUNT_SELF_SERVE = window.__IS_ACCOUNT_SELF_SERVE > -1
const __IS_SELF_SERVE = window.ACCOUNT_INFO ? window.ACCOUNT_INFO.isSelfServeAccount : false

export const IS_DEMO_ACCOUNT_ID = (__ACCOUNT_ID === 'WWW-WWW-WWWZ' || __ACCOUNT_ID === 'ZWW-WWW-WWWZ')
export const IS_NEW_DEMO_ACCOUNT = window.__IS_NEW_DEMO_ACCOUNT || false
export const ACCOUNT_ID = __ACCOUNT_ID
export const USER_NAME = __USER_NAME
export const USERNAME = __USERNAME
export const ACCOUNT_NAME = __ACCOUNT_NAME
export const ACCOUNT_ICON = __ACCOUNT_ICON
export const ACCOUNT_REGION = __ACCOUNT_REGION
export const DATE_TIME = __DATE_TIME
export const USER_AVATAR = __USER_AVATAR
export const IS_TEST_ACCOUNT = __IS_TEST_ACCOUNT
export const ACCOUNT_IS_INTEGRATED = __ACCOUNT_IS_INTEGRATED
export const USER_IS_INTERNAL = __USER_IS_INTERNAL
export const ACCOUNT_INFO = __ACCOUNT_INFO
export const EFFECTIVE_ROLE = __EFFECTIVE_ROLE
export const IS_GHOST = __IS_GHOST
export const TIMEZONE_OFFSET_MINS = window.__TIMEZONE_OFFSET_MINUTES || -(new Date()).getTimezoneOffset()
export const UNINSTALL_ENABLED = window.uninstallTrackingEnabled === undefined ? true : window.uninstallTrackingEnabled
export const INVALID_APPLE_CERTIFICATE = window.INVALID_APPLE_CERTIFICATE || false
export const INVALID_APPLE_CERTIFICATE_CAUSE = window.INVALID_APPLE_CERTIFICATE_CAUSE || ''
export const MAX_DRP = __maxDRP
export const SAMPLING_MODE = isNaN(__SAMPLING_MODE) ? 0 : __SAMPLING_MODE
export const SEGMENT_ROLE_INFO = __SEGMENT_ROLE_INFO
export const HOME_BOARD = __HOME_BOARD
export const NEW_CAMPAIGN = window.__NEW_CAMPAIGN || false
export const NEW_JOURNEY = window.__NEW_JOURNEY || false
export const GO_LICENSE_KEY = '73ff45e2ba1c28c702d95d76423d38f919a57563cb841ca0090412f7e8093f1d269dea2a55d7d9c680f846ee0a7bc18a88c56a7a901e0739e331d5d543e682f0e63226b512084388ac05249091fe2cf6ab6a61f497e571a288288de0fbabc29c5af7f3d457c90d'
export const IS_ACCOUNT_SELF_SERVE = __IS_ACCOUNT_SELF_SERVE // this need to be deprecated
export const IS_SELF_SERVE = __IS_SELF_SERVE

export const SEGMENT_TYPES = {
  LIVE: 1,
  BATCH: 0,
  BOOKMARKED: undefined
}
export const SHOW_NOTIFICATION = window.SHOW_NOTIFICATION === 'true'

export const readCookie = function readCookie (name) {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }
  return null
}

export const addToUrl = function addToURL (url, keyEqualsVal) {
  var separator = url.indexOf('?') > -1 ? '&' : '?'
  url += (separator + keyEqualsVal)
  return url
}

export const HAS_ANALYTICS_MODE = window.HAS_ANALYTICS_MODE || false
export const analyticsEngagementMode = window.analyticsEngagementMode || (typeof window.localStorage !== 'undefined' && window.localStorage.getItem('analytics_engagement_mode'))
export const NAG_ALERT = window.NAG_ALERT || ''

export const FIELDINDEX = {
  'profile': {
    'browser': 0,
    'device': 1,
    'addressable': 2,
    'gender': 3,
    'age': 4,
    'country': 8,
    'region': 9,
    'city': 10,
    'login_source': 11,
    'os': 12,
    'type': 13,
    'source': 14,
    'idn': 16,
    'email': 17,
    'push': 24,
    'phone': 25,
    'dndpush': 26,
    'dndemail': 27,
    'timezone': 28,
    'dndphone': 31,
    'reachability': 101,
    'geo_ex': 102,
    'dndwhatsapp': 35,
    'wa_tgt': 36,
    'wa_ticket': 37
  },
  'session': {
    'minute': 0,
    'amount': 1,
    'session_time': 2,
    'session_pages': 3,
    'source': 4,
    'Referrer Type': 5,
    'referrer': 6,
    'utm_src': 7,
    'utm_medium': 8,
    'utm_campaign': 9,
    'cookieIDs': 10,
    'utm_flag': 11,
    'first': 13,
    'time_of_the_day': 14,
    'page_url': 15,
    'page_referrer': 16,
    'day_of_the_week': 17,
    'day_of_the_month': 18,
    'last': 19
  },
  'app': {
    'OS Version': 0,
    'App Version': 4,
    'SDK Version': 1,
    'Make': 2,
    'Model': 3
  },
  'dev': {
    'Device Width': 0,
    'Device Height': 1,
    'Device PPI': 2
  },
  'reachability': {
    'All': 0,
    'Android Push': 1,
    'IOS Push': 2,
    'Windows Push': 3,
    'Email': 4,
    'Phone': 5,
    'Chrome Push': 6,
    'Firefox Push': 7,
    'Safari Push': 8,
    'MobilePush': 9,
    'WebPush': 10,
    'WhatsApp': 15
  }
}

export const OPERATOR = {
  'eq': 1,
  'gt': 2,
  'ge': 3,
  'lt': 4,
  'le': 5,
  'bw': 6,
  'co': 7,
  'neq': 8,
  'bwe': 9,
  'set': 10,
  'nset': 11,
  'nco': 12,
  'eqr': 13,
  'neqr': 14,
  'bg': 15, // belongs to
  'nbg': 16, // not belongs to
  'unknown': -1
}

export const WHERE_OPERATORS = [
  {
    text: '≥ (greater or equals)',
    value: 3,
    symbol: '≥'
  }, {
    text: '≏ (between)',
    value: 6,
    symbol: '≏'
  }, {
    text: '≤ (less than or equals)',
    value: 5,
    symbol: '≤'
  }, {
    text: '= (equals)',
    value: 1,
    symbol: '='
  }
]

export const SPECIAL_EVENTS = {
  PageEvent: -5,
  ScreenViewed: -6,
  CampaignSent: -7,
  CampaignBounced: -8
}

export const FUNCTIONS = {
  dayOfYear: 1,
  age: 2
}

export const DATATYPE = { // All server data types
  'str': 0,
  'num': 1,
  'enum': 2,
  'date': 3,
  'mixed': -2,
  'unknown': -1
}

export const DATATYPE_MAP = { // All server data types
  str: 'str',
  num: 'num',
  enum: 'enum',
  date: 'date',
  mixed: 'mixed',
  unknown: 'unknown'
}

export const XDATATYPE = { '0': 'str', '1': 'num', '2': 'enum', '3': 'date', '-2': 'mixed', '-1': 'unknown' }

export const GROUPBYFUNCTIONS = { 'sum': 1, 'avg': 2 }

export const EVENTSTOREFIELDS = {
  'profile': 1,
  'session': 2,
  'eventProps': 3,
  'items': 4,
  'profile_ex': 5,
  'app': 6,
  'dev': 7,
  'geo': 100,
  'geo_ex': 102
}

export const COMPARATOR_PROPERTIES = [
  {
    id: 'eq',
    text: '= equals',
    value: 'eq',
    symbol: '='
  },
  {
    id: 'set',
    text: '∃ exists',
    value: 'set',
    symbol: '∃'
  },
  {
    id: 'nset',
    text: '∄ does not exist',
    value: 'nset',
    symbol: '∄'
  },
  {
    id: 'co',
    text: '∋ contains',
    value: 'co',
    symbol: '∋'
  },
  {
    id: 'nco',
    text: '∌ does not contain',
    value: 'nco',
    symbol: '∌'
  },
  {
    id: 'neq',
    text: '≠ not equals',
    value: 'neq',
    symbol: '≠'
  },
  {
    id: 'bw',
    text: '≏ between',
    value: 'bw',
    symbol: '≏'
  },
  {
    id: 'gt',
    text: '> greater than',
    value: 'gt',
    symbol: '>'
  },
  {
    id: 'lt',
    text: '< less than',
    value: 'lt',
    symbol: '<'
  },
  {
    id: 'bg',
    text: '∋ includes',
    value: 'bg',
    symbol: '∋'
  },
  {
    id: 'nbg',
    text: '∌ excludes',
    value: 'nbg',
    symbol: '∌'
  }
]

export const PAGE_HANDLER_OPTIONS = [
  {
    text: '∋ (contains)',
    value: 3,
    symbol: '∋'
  },
  {
    text: '∌ (does not contain)',
    value: 28,
    symbol: '∌'
  },
  {
    text: '= (equals)',
    value: 1,
    symbol: '='
  },
  {
    text: '≠ (not equals)',
    value: 15,
    symbol: '≠'
  },
  {
    text: '/*/ (matches regex)',
    value: 29,
    symbol: '/*/'
  },
  {
    text: '^/*/ (does not match regex)',
    value: 30,
    symbol: '^/*/ '
  }
]

export const INTEREST_OPERATORS = [
  {
    text: '= (equals)',
    value: 1,
    symbol: '='
  },
  {
    text: '∋ (contains)',
    value: 7,
    symbol: '∋'
  }
]

export const BINARY_OPTIONS = [
  {
    id: 'yes',
    value: 'yes',
    text: 'Yes'
  }
]

export const DAYS_OF_WEEK = [
  {
    text: 'Monday',
    value: 1
  },
  {
    text: 'Tuesday',
    value: 2
  },
  {
    text: 'Wednesday',
    value: 3
  },
  {
    text: 'Thursday',
    value: 4
  },
  {
    text: 'Friday',
    value: 5
  },
  {
    text: 'Saturday',
    value: 6
  },
  {
    text: 'Sunday',
    value: 0
  }
]

export const EVENT_TIME_OPTIONS = {
  sf: 'First Time',
  sl: 'Last Time',
  count: 'Occurrences'
}

export const EVENT_TIME_COMPARATOR = {
  gt: 'Greater Than',
  lt: 'Less Than',
  bw: 'Between'
}

export const XLATEIDS = { // 0==browser; 1==device; 12==os etc. -- from above FIELDINDEX IDs
  // both PROFILE & SESSION fields are mapped here...since not found a clash so far in what is required
  0: {
    'Others': 0,
    'Firefox': 1,
    'Chrome': 2,
    'Seamonkey': 3,
    'Safari': 4,
    'Opera': 5,
    'IE': 6,
    'UCBrowser': 7,
    'MobileApp': 8
  }, // 0==Browser
  1: { 'Desktop': 0, 'Mobile': 1, 'Tablet': 2, 'TV': 3 }, // 1==Device
  3: { 'Unknown': 0, 'Male': 1, 'Female': 2, 'Others': 3 }, // 3=Gender
  4: { 'Direct': 0, 'Others': 1, 'Search': 2, 'Social': 3, 'Email': 4, 'UnAvailable': 5 }, // 4==Session Source
  12: { 'Others': 0, 'Android': 1, 'iOS': 2, 'Windows': 3, 'Mac': 4, 'BlackBerry': 5, 'Linux': 6 }, // 12==OS
  13: { 'Anonymous': 10, 'New': 0, 'Repeat': 1, 'Addressable': 2, 'Customer': 3 }, // Anonymous is forced as 10 -- server still has 0=New/1=Repeat
  14: { 'Direct': 0, 'Others': 1, 'Search': 2, 'Social': 3, 'Email': 4, 'UnAvailable': 5 } // 14==Profile Source (same values as session source)
}

export const TREND = { 'Daily': 1, 'Weekly': 2, 'Monthly': 3 }

export const XLATENAMESPROFILE = {
  0: {
    0: 'Others',
    1: 'Firefox',
    2: 'Chrome',
    3: 'Seamonkey',
    4: 'Safari',
    5: 'Opera',
    6: 'IE',
    7: 'UCBrowser',
    8: 'MobileApp'
  }, // Browser
  1: { 0: 'Desktop', 1: 'Mobile', 2: 'Tablet', 3: 'TV' }, // 1==Device
  3: { 0: 'Unknown', 1: 'Male', 2: 'Female', 3: 'Others' }, // 3==Gender
  5: { 0: 'Unknown', 1: 'Employed', 2: 'Not Employed' }, // 5==work
  4: {
    0: 'Unknown',
    1: 'Below 18 Years',
    2: '18 to 24 years',
    4: '25 to 40 years',
    5: '41 to 60 years',
    6: 'Over 60 years'
  }, // 4==Age
  7: { 0: 'Unknown', 1: 'School', 2: 'College', 3: 'Graduate' },
  12: { 0: 'Others', 1: 'Android', 2: 'iOS', 3: 'Windows', 4: 'Mac', 5: 'BlackBerry', 6: 'Linux' }, // OS
  13: { 10: 'Anonymous', 0: 'New', 1: 'Repeat', 2: 'Addressable', 3: 'Customer' }, // Anonymous is forced as 10 -- server still has 0=New/1=Repeat
  14: { 0: 'Direct', 1: 'Others', 2: 'Search', 3: 'Social', 4: 'Email', 5: 'UnAvailable' },
  17: { 0: 'No', 1: 'Yes' },
  24: { 0: 'No', 1: 'Yes' },
  26: { 0: 'No', 1: 'Yes' },
  27: { 0: 'No', 1: 'Yes' },
  25: { 0: 'No', 1: 'Yes' },
  31: { 0: 'No', 1: 'Yes' },
  35: { 0: 'Yes', 1: 'No' },
  101: {
    0: 'All',
    1: 'Android Push',
    2: 'IOS Push',
    3: 'Windows Push',
    4: 'Email',
    5: 'Phone',
    6: 'Chrome Push',
    7: 'Firefox Push',
    8: 'Safari Push',
    9: 'MobilePush',
    10: 'WebPush',
    15: 'WhatsApp'
  }
}

export const RESET_DATA_OPTIONS_ACCOUNT = ['WWW-WWW-WWRZ', 'ZWW-WWW-WWRZ', 'RWW-WWW-WW4Z']

export const basicEPHandler = {
  key: {
    pageReferrer: 0,
    pageUrl: 1,
    currentEvent: 2,
    oldEvent: 3,
    geo: 4,
    pageCount: 5,
    formFactorType: 6,
    custom: 7,
    appField: 8,
    multipleGeo: 10,
    deviceField: 11,
    geoExclude: 14,
    reachability: 15,
    browser: 16,
    deviceOS: 17,
    gender: 18,
    segments: 19
  },
  formFactorType: {
    Desktop: 0, Tablet: 1, Mobile: 2
  },
  xlateFormFactorType: {
    0: 'Desktop', 1: 'Tablet', 2: 'Mobile'
  },
  xlateKeys: {
    0: 'pageReferrer', 1: 'pageUrl', 2: 'currentEvent', 3: 'oldEvent', 4: 'geo', 5: 'pageCount'
  },
  operator: {
    gt: 0,
    eq: 1,
    lt: 2,
    co: 3,
    bw: 4,
    country: 5,
    state: 6,
    city: 7,
    firstSeen: 8,
    lastSeen: 9,
    neverSeen: 10,
    seenBefore: 11,
    eventProperties: 12,
    occurrences: 13,
    lastSeenBeforeDependentPeriod: 14,
    neq: 15,
    dateWindow: 16,
    days: 17,
    hours: 18,
    minutes: 19,
    relative: 20,
    absolute: 21,
    mmdd: 22,
    weeks: 23,
    months: 24,
    years: 25,
    set: 26,
    nset: 27,
    nco: 28,
    eqr: 29,
    neqr: 30,
    utmProperties: 31,
    bg: 32,
    nbg: 33
  },
  xlateOperator: {
    0: 'gt',
    1: 'eq',
    2: 'lt',
    3: 'co',
    4: 'bw',
    5: 'country',
    6: 'state',
    7: 'city',
    8: 'firstSeen',
    9: 'lastSeen',
    10: 'neverSeen',
    11: 'seenBefore',
    12: 'eventProperties',
    15: 'neq',
    28: 'nco',
    29: 'eqr',
    30: 'neqr',
    31: 'utmProperties',
    32: 'bg',
    33: 'nbg'
  },
  operatorString: {
    0: 'greater than',
    1: 'equals',
    2: 'less than',
    3: 'contains',
    4: 'between',
    5: 'country',
    6: 'state',
    7: 'city',
    8: 'firstSeen',
    9: 'lastSeen',
    10: 'neverSeen',
    11: 'seenBefore',
    12: 'eventProperties',
    15: 'not equals',
    28: 'does not contain',
    29: 'matches regex',
    30: 'does not match regex',
    31: 'utmProperties',
    32: 'include',
    33: 'exclude'
  },
  app: {
    'OS Version': 0, 'App Version': 4, 'SDK Version': 1, 'Make': 2, 'Model': 3
  },
  dev: {
    'Device Width': 0, 'Device Height': 1, 'Device PPI': 2
  }
}

export const FIELDNAMES = {
  'profile': {
    0: 'Browser',
    1: 'Device',
    2: 'Addressable',
    3: 'Gender',
    4: 'Age',
    8: 'Country',
    9: 'Region',
    10: 'City',
    11: 'login_source',
    12: 'OS',
    13: 'User Type',
    14: 'Source',
    15: 'Photo',
    16: 'Identity',
    17: 'Has Email Address',
    18: 'Platform',
    19: 'Platform Type',
    24: 'Has Device Token',
    25: 'Has Phone Number',
    26: 'Unsubscribed Push',
    27: 'Unsubscribed Email',
    28: 'Timezone',
    29: 'daylightsaving',
    30: 'push_email_ex',
    31: 'Unsubscribed SMS',
    100: 'geo',
    101: 'reachability',
    102: 'geo_ex',
    35: 'Unsubscribed WhatsApp',
    36: 'wa_tgt',
    37: 'wa_ticket'
  },

  'session': {
    0: 'Hour',
    1: 'Amount',
    2: 'Minutes before Event',
    3: 'Pages before Event',
    4: 'Session Source',
    5: 'Referrer Type',
    6: 'Referrer',
    7: 'utm_src',
    8: 'utm_medium',
    9: 'utm_campaign',
    10: 'cookieIDs',
    11: 'utm_flag',
    13: 'First time',
    14: 'Time of the day',
    15: 'Page name',
    16: 'Page referrer',
    17: 'Day of the week',
    18: 'Day of the month',
    19: 'Last time'
  },
  'app': { 0: 'OS Version', 4: 'App Version', 1: 'SDK Version', 2: 'Make', 3: 'Model' },
  'dev': { 0: 'Device Width', 1: 'Device Height', 2: 'Device PPI' }
}

export const XLATEOPERATOR = {
  1: 'eq',
  2: 'gt',
  3: 'ge',
  4: 'lt',
  5: 'le',
  6: 'bw',
  7: 'co',
  8: 'neq',
  9: 'bwe',
  10: 'set',
  11: 'nset',
  12: 'nco',
  13: 'eqr',
  14: 'neqr',
  15: 'bg',
  16: 'nbg'
}

export const timeUnitEnums = {
  m: 'minutes',
  h: 'hours',
  d: 'days'
}

export const occurrencesOptions = {
  gt: {
    value: 'gt',
    text: 'greater than'
  },
  eq: {
    value: 'eq',
    text: 'equals to'
  },
  lt: {
    value: 'lt',
    text: 'less than'
  },
  bw: {
    value: 'bw',
    text: 'between'
  }
}

export const TIME_UNITS = [timeUnitEnums.m, timeUnitEnums.h, timeUnitEnums.d]

export const RESTRICTED_EVENTS = {
  SystemRestricted: window.location.href.indexOf('showDebugEvents=true') !== -1 ? ['Stayed'] : ['Stayed', 'Identity Set', 'Identity Reset', 'Identity Error', 'Reachable By', 'State Transitioned', 'Push Unregistered']
}
// SUC-29248, SUC-84748 Handling for "App Screen Viewed" raised as custom event by MPL
if (typeof __ACCOUNT_ID !== 'undefined' && (__ACCOUNT_ID === 'W44-755-WK5Z' || __ACCOUNT_ID === '6K7-467-K55Z' || __ACCOUNT_ID === 'RZK-98Z-495Z' || __ACCOUNT_ID === '888-774-ZW6Z')) {
  RESTRICTED_EVENTS.offlineTriggerRestricted = RESTRICTED_EVENTS['SystemRestricted'].concat('Web Page Viewed', 'Notification Sent')
} else {
  RESTRICTED_EVENTS.offlineTriggerRestricted = RESTRICTED_EVENTS['SystemRestricted'].concat('App Screen Viewed', 'Web Page Viewed', 'Notification Sent')
}
RESTRICTED_EVENTS.systemEventsRestricted = RESTRICTED_EVENTS['SystemRestricted'].concat('UTM Visited', 'Notification Clicked', 'Notification Viewed', 'Notification Sent', 'Notification Delivered', 'Notification Replied', 'Any Event', 'Session Concluded')
RESTRICTED_EVENTS.MiniWhoRestricted = RESTRICTED_EVENTS['SystemRestricted'].concat('App Screen Viewed', 'Web Page Viewed', 'Notification Sent')
RESTRICTED_EVENTS.BigWhoRestricted = RESTRICTED_EVENTS['SystemRestricted']

RESTRICTED_EVENTS.webTriggeredRestricted = RESTRICTED_EVENTS['offlineTriggerRestricted'].concat('UTM Visited', 'Notification Clicked', 'Push Impressions')
RESTRICTED_EVENTS.inAppTriggeredRestricted = RESTRICTED_EVENTS['offlineTriggerRestricted'].concat('Push Impressions')
