<template>
  <div class="d-flex justify-space-around">
    <ct-switch
      value
      disabled
    ></ct-switch>
    <ct-switch
      value
      input-value="true"
      disabled
    ></ct-switch>
  </div>
</template>

<script>
import CtSwitch from 'clevertap-dashboard-components/components/Switch/CtSwitch'
export default {
  components: {
    CtSwitch
  }
}
</script>
