export async function getAPI (path) {
  let api = {}
  switch (path.replace('/api', '')) {
    case '/dropdowns/single-select':
    case '/dropdowns/multi-select':
      api = await import('clevertap-dashboard-components/components/Select/CtUnifiedSelect')
      break
    case '/selection-controls/checkbox':
      api = await import('clevertap-dashboard-components/components/Checkbox/CtCheckbox')
      break
    case '/selection-controls/radio':
      api = await import('clevertap-dashboard-components/components/Radio/CtUnifiedRadio')
      break
    case '/selection-controls/switch':
      api = await import('clevertap-dashboard-components/components/Switch/CtSwitch')
      break
    case '/buttons/SimpleButton':
      api = await import('clevertap-dashboard-components/components/Button/CtButton')
      break
    case '/alerts/AlertGeneric':
      api = await import('clevertap-dashboard-components/components/Alert/CtAlert')
      break
    case '/textField':
      api = await import('clevertap-dashboard-components/components/Textfield/CtTextfield')
      break
    case '/buttons/ButtonGroup':
      api = await import('clevertap-dashboard-components/components/Button/CtButton')
      break
    case '/Search/Search':
      api = await import('clevertap-dashboard-components/components/Autocomplete/CtAutocomplete')
      break
    case '/textArea':
      api = await import('clevertap-dashboard-components/components/Textarea/CtTextarea')
      break
    case '/breadcrumbs':
      api = await import('clevertap-dashboard-components/components/Breadcrumbs/CtBreadcrumbs')
      break
    case '/modal':
      api = await import('clevertap-dashboard-components/components/Modal/CtModal')
      break
    case '/chips':
      api = await import('clevertap-dashboard-components/components/Chip/CtChip')
      break
    case '/card':
      api = await import('clevertap-dashboard-components/components/Card/CtCard')
      break
    case '/tags':
      api = await import('clevertap-dashboard-components/components/Tag/CtTag')
      break
    case '/timePicker':
      api = await import('clevertap-dashboard-components/components/TimePicker/CtTimePicker')
      break
    case '/tables':
      api = await import('clevertap-dashboard-components/components/Table/CtTable')
      break
    default:
      return {}
  }
  return api.api ? api.api : null
}
