import MultiSelectDefault from './MultiSelectDefault'
import MultiSelectDefaultWithPersistentHint from './MultiSelectDefaultWithPersistentHint'
import MultiSelectDefaultWithLabel from './MultiSelectDefaultWithLabel'
import MultiSelectDefaultWithLabelAndPersistentHint from './MultiSelectDefaultWithLabelAndPersistentHint'
import MultiSelectDefaultWithSearch from './MultiSelectDefaultWithSearch'
import MultipleSelectDefaultWithHelpText from './MultipleSelectDefaultWithHelpText'
import ValidationError from './ValidationError'
import MultiSelectCategorized from './MultiSelectCategorized'
import MultipleSelectDisabled from './MultipleSelectDisabled'
import MultiselectDefaultWithChips from './MultiselectDefaultWithChips'
// examplesImporter will auto import all the Vue files in the examples folder.
// NOTE: do not forget to add the /* preval */ comment. That's the magic ingredient here
import examplesArray from /* preval */ '../../../../examplesImporter'

const exampleComponents = {
  MultiSelectDefault,
  MultiSelectDefaultWithPersistentHint,
  MultiSelectDefaultWithLabel,
  MultiSelectDefaultWithLabelAndPersistentHint,
  MultiSelectDefaultWithSearch,
  MultipleSelectDefaultWithHelpText,
  ValidationError,
  MultiSelectCategorized,
  MultipleSelectDisabled,
  MultiselectDefaultWithChips
}

export { examplesArray, exampleComponents }
