<template>
  <ct-unified-radio row :items="items" v-model="value"></ct-unified-radio>
</template>

<script>
import CtUnifiedRadio from 'clevertap-dashboard-components/components/Radio/CtUnifiedRadio'

export default {
  name: 'RadioSelect',
  data () {
    return {
      items: [
        { label: 'Radio 1', value: 'radio-1' },
        { label: 'Radio 2', value: 'radio-2' }
      ],
      value: 'radio-1'
    }
  },
  components: {
    CtUnifiedRadio
  }
}
</script>
