<script>
import { VTooltip } from 'vuetify/lib'

let _VTooltip = VTooltip

export default {
  name: 'CtTooltip',
  extends: _VTooltip,
  props: {
    zIndex: {
      type: [Number, String],
      default: 100
    }
  }
}
</script>

<style>

</style>
